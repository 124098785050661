import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientAllocationActions, IThunkAddClientAllocation } from '../types';

const onAddOwnClientAllocationGU: IThunkAddClientAllocation = thunk(
  async (_actions: Actions<IClientAllocationActions>, { clinicId, payload }) => {
    await api.clientAllocation.addOwnClientAllocationGU(
      { clinicId },
      {
        clients: payload.allocations[0].clients,
        locationGroupIds: payload.allocations[0].locationGroupIds,
        from: payload.from,
        to: payload.to,
        includeWeekends: payload.includeWeekends,
      },
    );
  },
);

export default onAddOwnClientAllocationGU;
