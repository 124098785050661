import { differenceInSeconds, format } from 'date-fns';
import { action } from 'easy-peasy';

import { storeUtilizationData } from '~/services/localStorage/utilization';

import { dateRequestFormat } from '~/ui/constants/dateFormat';
import routeToModuleMap from '../constants/moduleRoutes';

import { IActionSetUtilizationModule, IUtilizationUserDataItem } from '../types';

const setUtilizationModule: IActionSetUtilizationModule = action((state, payload) => {
  const { route, userId, teamId = null } = payload || {};

  if (userId) {
    // if user provided we set current user info
    state.user = { id: userId, teamId };
  }

  const user =
    state.user?.id && `${state.user.id}${state.user.teamId ? `_${state.user.teamId}` : ''}`;

  const currentUserData = user ? state.users[user] : undefined;
  const currentDate = format(new Date(), dateRequestFormat);
  const dateModules = currentUserData
    ? currentUserData[currentDate]
    : ({} as IUtilizationUserDataItem);

  const currentModuleRoute = state.currentModule?.module;
  const currentModuleDate = state.currentModule?.date;

  if (user && currentModuleRoute) {
    // if the module record for this day already exists, we sum timeSpent, if not just store value in second
    const prevTimeSpent = dateModules?.[currentModuleRoute]?.timeSpent || 0;
    const timeSpent = currentModuleDate
      ? differenceInSeconds(new Date(), new Date(currentModuleDate))
      : 0;

    const newData = {
      ...dateModules,
      [currentModuleRoute]: { timeSpent: prevTimeSpent + timeSpent },
    };
    if (currentUserData) {
      state.users[user][currentDate] = newData;
    } else {
      state.users[user] = {
        [currentDate]: newData,
      };
    }
  }

  const newModuleRoute = route ? routeToModuleMap[route] : undefined;

  if (newModuleRoute) {
    // if provided module should be tracked then store it's data
    state.currentModule = {
      module: newModuleRoute,
      date: new Date().toISOString(),
    };
  } else if (!route) {
    // if route is not provided then reset date, which stops traking for current module (like app folded)
    if (state.currentModule?.date) {
      state.currentModule.date = null;
    }
  } else {
    // if provided module shouldn't be tracked then reset current module
    state.currentModule = null;
  }

  storeUtilizationData(state.users);
});

export default setUtilizationModule;
