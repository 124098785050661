import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import Form from '../../reusable/ClientAllocationForm';

import useRole from '~/store/user/hooks/useRole';

import { NETWORK, VIEW_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import {
  clientMapper,
  prepareDefaultValues,
  sortByName,
  teamMemberMapper,
} from '~/utils/clientAllocation';
import extractDateFromString from '~/utils/date/extractDateFromString';
import { extractErrorMessage } from '~/utils/error/error';
import extractFullName from '~/utils/text/extractFullName';

type IParams = {
  actTeamId: string;
  clientAllocationDate: string;
};

const DuplicateClientAllocation = (): ReactElement => {
  const { actTeamId, clientAllocationDate } = useParams<IParams>();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [clients, setClients] = useState([]);

  const {
    clinic: userClinic,
    id: userId,
    photo,
    ...user
  } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const clientAllocation = useStoreState(state => state.clientAllocation.current);
  const locationGroups = useStoreState(state => state.clientAllocation.locationGroups);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { onGetClinicActTeam, onGetActTeamsAvailable } = useStoreActions(
    actions => actions.actTeam,
  );
  const onGetAllocationClientsByGroup = useStoreActions(
    actions => actions.clientAllocation.onGetAllocationClientsByGroup,
  );
  const {
    onGetClientAllocation,
    onGetOwnClientAllocation,
    onGetOwnClientAllocationGU,
    onGetAllocationClientsByGroupGU,
  } = useStoreActions(actions => actions.clientAllocation);

  const userFullName = useMemo(() => extractFullName(user), [user]);
  const { isActTeam, isActTeamMember, isGlobalUser } = useRole();

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };

      const allocationDate = `${clientAllocationDate}T00:00:00Z`;

      await onGetMyClinic();

      if (isGlobalUser) {
        await onGetActTeamsAvailable({ clinicId: String(userClinic.id) });
        await onGetOwnClientAllocationGU({
          clientAllocationDate: allocationDate,
        });
        await onGetAllocationClientsByGroupGU({ clinicId: String(userClinic.id) });
        const availableTeamMembers = [
          { id: userId, fullName: userFullName, photo, isDeleted: false },
        ];
        setTeamMembers(availableTeamMembers);
      } else {
        await Promise.all([
          onGetClinicActTeam(payload),
          api.clientAllocation
            .getRelatedClients(String(userClinic.id), actTeamId)
            .then(r => r.data)
            .then(data => data.map(clientMapper))
            .then(r => setClients(r)),
          api.actTeamMember
            .getActTeamMemberList(String(userClinic.id), actTeamId)
            .then(r => r.data)
            .then(data => data.sort(sortByName).map(teamMemberMapper))
            .then(r => {
              if (isActTeamMember) {
                setTeamMembers(r.filter(member => member.id === userId));
              } else {
                setTeamMembers(r);
              }
            }),
          isActTeamMember
            ? onGetOwnClientAllocation({
                clientAllocationDate: allocationDate,
              })
            : onGetClientAllocation({
                clinicId: String(userClinic.id),
                teamId: actTeamId,
                clientAllocationDate: allocationDate,
              }),
          onGetAllocationClientsByGroup({ clinicId: String(userClinic.id), teamId: actTeamId }),
        ]);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    actTeamId,
    clientAllocationDate,
    isActTeamMember,
    isGlobalUser,
    onGetActTeamsAvailable,
    onGetAllocationClientsByGroup,
    onGetAllocationClientsByGroupGU,
    onGetClientAllocation,
    onGetClinicActTeam,
    onGetMyClinic,
    onGetOwnClientAllocation,
    onGetOwnClientAllocationGU,
    photo,
    showError,
    userClinic.id,
    userFullName,
    userId,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const userIdsWithClients = useMemo(() => clientAllocation?.map(u => u.id), [clientAllocation]);

  const userIds = useMemo(() => teamMembers.map(u => u.id), [teamMembers]);

  const filteredClientAllocationUsers = useMemo(
    () => clientAllocation?.filter(item => userIds.includes(item.user.id)),
    [clientAllocation, userIds],
  );

  // filter out deleted and with no assignments
  const filteredTeamMembers = useMemo(
    () =>
      teamMembers.filter(member => !member.isDeleted || userIdsWithClients?.includes(member.id)),
    [teamMembers, userIdsWithClients],
  );

  if (loading || (!actTeam && !isGlobalUser) || !clientAllocation) return <Loader />;

  const breadcrumbItems = [
    ...(isGlobalUser ? [{ to: NETWORK, title: `${userClinic.name} Teams` }] : []),
    ...(isActTeam
      ? [
          {
            to: {
              pathname: VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                ':clientAllocationDate',
                String(clientAllocation?.[0]?.date.split('T')[0]),
              ),
            },
            title: 'Client Allocation',
          },
        ]
      : []),
    ...(!isActTeam && !isGlobalUser ? [{ to: NETWORK, title: `${userClinic.name} Teams` }] : []),
    { to: pathname, title: 'Duplicate Client Allocation' },
  ];

  const defaultValues = {
    includeWeekends: false,
    ...prepareDefaultValues(filteredClientAllocationUsers),
  };

  return (
    <div>
      <h2>{`Duplicate Client Allocation  | ${extractDateFromString(clientAllocation[0].date)}`}</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        defaultValues={defaultValues}
        teamMembers={filteredTeamMembers}
        clients={clients}
        clientsList={clients}
        actTeamId={actTeamId}
        clinicId={String(userClinic.id)}
        clientAllocations={clientAllocation}
        clientAllocationDate={clientAllocation[0].date}
        locationGroups={locationGroups}
      />
    </div>
  );
};

export default DuplicateClientAllocation;
