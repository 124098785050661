import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import Checkbox from '~/ui/components/inputs/Checkbox';
import RadioGroup from '~/ui/components/inputs/RadioGroup';
import DateRangePicker from '~/ui/components/inputs/DateRangePicker';
import { allocationModeOptions } from '../../../constants/allocationMode';

import styles from './AddAllocationFormToolbar.module.scss';

interface IProps {
  clientAllocationDate: string;
}

const AddAllocationFormToolbar: FC<IProps> = ({ clientAllocationDate }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <DateRangePicker
          label="Select Date Range"
          control={control}
          name="dateRange"
          errors={errors}
        />
      </Grid>
      <Grid item sm={3} className={styles.checkboxContainer}>
        <Checkbox
          name="includeWeekends"
          size="small"
          control={control}
          label="Include weekends"
          errors={errors}
        />
      </Grid>
      <Grid item sm={5} className={styles.radioGroupContainer}>
        {!clientAllocationDate && (
          <RadioGroup
            control={control}
            name="allocationMode"
            errors={errors}
            options={allocationModeOptions}
            size="small"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AddAllocationFormToolbar;
