import delay from 'lodash/delay';
import { useEffect, useState } from 'react';

const INACTIVITY_DELAY = 1000 * 60 * 60;
const CALLBACK_DELAY = 3000;

interface IUseInactivityProps {
  timeout?: number;
  skip?: boolean;
  callbackDellay?: number;
  inactivityCallback: () => void;
  activityCallback?: () => void;
}

const useInactivity = ({
  timeout = INACTIVITY_DELAY,
  skip = false,
  callbackDellay = CALLBACK_DELAY,
  inactivityCallback,
  activityCallback,
}: IUseInactivityProps): void => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    if (skip) return undefined;

    // Function to reset inactivity timer on user interaction
    const resetTimer = () => {
      setLastActivity(Date.now());

      if (inactive) {
        setInactive(false);
        activityCallback?.(); // Only trigger when transitioning from inactive to active
      }
    };

    // List of events to track user activity
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'];

    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    const checkInactivity = () => {
      if (!inactive && Date.now() - lastActivity > timeout) {
        setInactive(true);
        delay(() => {
          inactivityCallback();
        }, callbackDellay);
      }
    };

    const intervalId = setInterval(checkInactivity, 1000);

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
      clearInterval(intervalId);
    };
  }, [lastActivity, timeout, skip, callbackDellay, inactivityCallback, activityCallback, inactive]);
};

export default useInactivity;
