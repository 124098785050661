import { action } from 'easy-peasy';

import { IActionSetUtilizationOverviewReport } from '../types';

const setUtilizationOverviewReport: IActionSetUtilizationOverviewReport = action(
  (state, payload) => {
    const { items, pagination } = payload;

    state.utilization.list =
      pagination.pageNumber === 1 ? items : [...state.utilization.list, ...items];
    state.utilization.pagination = pagination;
  },
);

export default setUtilizationOverviewReport;
