import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';

import api from '~/services/api';
import { clientMapper, sortByName, teamMemberMapper } from '~/utils/clientAllocation';
import { extractErrorMessage } from '~/utils/error/error';

import Form from '../../reusable/ClientAllocationForm';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';

import { NETWORK } from '~/ui/constants/paths';

type IParams = {
  actTeamId: string;
};

const AddClientAllocation = (): ReactElement => {
  const { actTeamId } = useParams<IParams>();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  const { clinic: userClinic, id: userId } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const locationGroups = useStoreState(state => state.clientAllocation.locationGroups);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const onGetAllocationClientsByGroup = useStoreActions(
    actions => actions.clientAllocation.onGetAllocationClientsByGroup,
  );

  const { isActTeam, isActTeamMember } = useRole();

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };

      await Promise.all([
        onGetMyClinic(),
        onGetClinicActTeam(payload),
        api.clientAllocation
          .getRelatedClients(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(r => {
            setClientsList(
              r.map(client => ({
                id: client.id,
                name: `${client.firstName} ${client.lastName}`,
                photo: client.photo,
                team: client.actTeam,
              })),
            );
            return r;
          })
          .then(data => data.map(clientMapper))
          .then(r => setClients(r)),
        api.actTeamMember
          .getActTeamMemberList(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(data => (data || []).sort(sortByName).map(teamMemberMapper))
          .then(r => {
            if (isActTeamMember) {
              setTeamMembers(r.filter(member => member.id === userId));
            } else {
              setTeamMembers(r);
            }
          }),
        onGetAllocationClientsByGroup({ clinicId: String(userClinic.id), teamId: actTeamId }),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    actTeamId,
    isActTeamMember,
    onGetAllocationClientsByGroup,
    onGetClinicActTeam,
    onGetMyClinic,
    showError,
    userClinic.id,
    userId,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !actTeam) return <Loader />;

  const breadcrumbItems = [
    ...(isActTeam
      ? [
          {
            title: actTeam.name,
            to: {
              pathname: NETWORK,
            },
          },
        ]
      : [{ to: NETWORK, title: `${userClinic.name} Teams` }]),
    { to: pathname, title: 'Add Client Allocation' },
  ];

  return (
    <div>
      <h2>Add Client Allocation</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        teamMembers={teamMembers}
        clients={clients}
        actTeamId={actTeamId}
        locationGroups={locationGroups}
        clinicId={String(userClinic.id)}
        clientsList={clientsList}
      />
    </div>
  );
};

export default AddClientAllocation;
