import { FC, ReactElement } from 'react';
import {
  Grid,
  Accordion,
  Typography,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

import { ITeamMemberMapped } from '~/utils/clientAllocation';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as GlobeIcon } from '~/ui/assets/images/global.svg';
import PrivateImage from '~/ui/components/common/PrivateImage';
import ErrorTooltip from '~/ui/pages/ActTeam/reusable/ErrorTooltip';
import userPhotoIcon from '~/ui/assets/images/userPhotoIcon.svg';
import { IAllocationClientsByGroup } from '~/services/api/clientAllocation/types';
import AccordionMembersDetailsFields from './components/AccordionMembersDetails/AccordionMembersDetailsFields';
import { IClientOption } from '~/store/client/types';
import { AllocationMode } from '../../constants/allocationMode';
import useSelectedClientsData from '../../hooks/useSelectedClientsData';

import styles from './AccordionMembers.module.scss';

interface IProps {
  member: ITeamMemberMapped;
  datesValidation?: { isValid: boolean; users: { userId: number; unavailableDates: string[] }[] };
  clientsList: IClientOption[];
  hasGlobalUserRole: boolean;
  clientAllocationDate: string;
  errorDates: string[];
  showDifferentTeamErrorMessage: boolean;
  renderSelect: () => ReactElement;
  allocationMode: AllocationMode;
  locationGroups: IAllocationClientsByGroup[];
  handleRemoveClient: (id: number, field: string) => void;
  handleRemoveClientInLocationGroup: (id: number, field: string, groupName?: string) => void;
  formFieldName: string;
}

const AccordionMembers: FC<IProps> = ({
  member,
  datesValidation,
  clientsList,
  allocationMode,
  clientAllocationDate,
  hasGlobalUserRole,
  errorDates,
  locationGroups,
  showDifferentTeamErrorMessage,
  renderSelect,
  handleRemoveClient,
  handleRemoveClientInLocationGroup,
  formFieldName,
}) => {
  const { getClientsByLocationGroup, getSelectedClients, getSelectedFieldsByName } =
    useSelectedClientsData(formFieldName);

  const watchedClients = getSelectedFieldsByName('clients', formFieldName) as number[];
  const watchedGroups = getSelectedFieldsByName('groups', formFieldName) as number[];

  const selectedClients = getSelectedClients(clientsList);
  const selectedClientsByLocationGroup = getClientsByLocationGroup(locationGroups, watchedGroups);

  if (member.isDeleted && !clientAllocationDate) {
    return null;
  }

  const filteredClients = selectedClients.filter(sClient =>
    (watchedClients || []).includes(sClient.id),
  );

  return (
    <>
      <Accordion className={styles.accordion} TransitionProps={{ unmountOnExit: true }}>
        <MuiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs={4} className={styles.accordionSummaryItemName}>
              <div className={styles.photoContainer}>
                <PrivateImage
                  className={styles.image}
                  src={member.photo || userPhotoIcon}
                  height={30}
                  alt="avatar"
                />
                {!hasGlobalUserRole && (
                  <div className={styles.absoluteIcon}>
                    <GlobeIcon width={20} height={20} />
                  </div>
                )}
              </div>
              <Typography>{member.fullName}</Typography>
              {!datesValidation?.isValid && !!errorDates?.length && (
                <ErrorTooltip items={errorDates} />
              )}
            </Grid>

            <Grid item xs={4} className={styles.accordionSummaryItemCenter}>
              <Typography>{watchedClients?.length || 0} Allocated Clients</Typography>
            </Grid>

            <Grid item xs={4} className={styles.accordionSummaryItemRight}>
              <Typography>Close Details</Typography>
            </Grid>
          </Grid>
        </MuiAccordionSummary>

        <AccordionMembersDetailsFields
          allocationMode={allocationMode}
          selectedClientsByLocationGroup={selectedClientsByLocationGroup}
          selectedClients={filteredClients}
          renderSelect={renderSelect}
          formFieldName={formFieldName}
          handleRemoveClient={handleRemoveClient}
          handleRemoveClientInLocationGroup={handleRemoveClientInLocationGroup}
          watchedClients={watchedClients}
        />
      </Accordion>

      {showDifferentTeamErrorMessage && (
        <Grid item sm={12} className={styles.errorMessage}>
          <span>Note: this user has assigned client allocations for this date on other teams</span>
        </Grid>
      )}
    </>
  );
};

export default AccordionMembers;
