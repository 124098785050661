import { thunk } from 'easy-peasy';
import api from '~/services/api';

import { IThunkAddClientAllocation } from '../types';

const onAddClientAllocation: IThunkAddClientAllocation = thunk(
  async (_actions, { clinicId, teamId, payload }) => {
    await api.clientAllocation.addClientAllocation({ clinicId, teamId }, payload);
  },
);

export default onAddClientAllocation;
