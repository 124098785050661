import {
  IAllocationClientValues,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';

export const getAllocationIdByUserId = (
  id: number,
  clientAllocations: IClientAllocationDetailed[],
): number =>
  clientAllocations?.find(allocation => allocation.user.id === id && !allocation.isArchived)?.id;

export const getClientsFormFieldName = (teamMemberId: number, allocationId?: number): string =>
  `_${teamMemberId}${allocationId ? `_${allocationId}` : ''}`;

export const getMemoFormFieldName = (clientId: number, fieldNameMemberId: string): string =>
  `memo_${clientId}${fieldNameMemberId}`;

export const getPayloadClients = (
  clients: number[],
  values: Record<string, unknown>,
  teamMemberId: number,
  clientAllocationId?: number,
): IAllocationClientValues[] =>
  clients.map(clientId => ({
    id: clientId,
    memo: values[
      getMemoFormFieldName(clientId, getClientsFormFieldName(teamMemberId, clientAllocationId)) ||
        null
    ] as string,
  }));
