export enum ReportType {
  MedicaIdRecertification = 'MedicaidStats',
  VisitMetrics = 'VisitMetrics',
  ClientStatus = 'ClientStatusReport',
  VisitsByTeam = 'VisitsByTeam',
  VisitsByClient = 'VisitsByClient',
  Dashboard = 'Dashboard',
  ClientAllocationVisits = 'AllocationVisits',
  Demographics = 'ClientDemographics',
  TreatmentPlans = 'TreatmentPlans',
  ClientTreatmentPlans = 'ClientTreatmentPlans',
  Medications = 'ClientMedications',
  ClientLengthOfStay = 'ClientLengthOfStay',
  LocationGroup = 'LocationGroups',
  Toxicology = 'Toxicology',
  LastClientContact = 'LastContact',
  VisitStatus = 'VisitStatus',
  Utilization = 'Utilization',
  Click = 'Click',
  LocationSharing = 'LocationSharing',
}

const reportTypes: Record<ReportType, string> = {
  [ReportType.MedicaIdRecertification]: 'Medicaid Recertification Statistics',
  [ReportType.VisitMetrics]: 'Visit Metrics Report',
  [ReportType.ClientStatus]: 'Client Status Report',
  [ReportType.VisitsByTeam]: 'Visits Report by Team',
  [ReportType.VisitsByClient]: 'Visits Report by Client',
  [ReportType.Dashboard]: 'Dashboard',
  [ReportType.ClientAllocationVisits]: 'Client Allocation Visits',
  [ReportType.Demographics]: 'Client Demographics and Health Details',
  [ReportType.TreatmentPlans]: 'Treatment Plan Report',
  [ReportType.ClientTreatmentPlans]: 'Treatment Plans by Client',
  [ReportType.Medications]: 'Client Medication Details',
  [ReportType.ClientLengthOfStay]: 'Client Length of Stay Details',
  [ReportType.LocationGroup]: 'Location Group',
  [ReportType.Toxicology]: 'Client Toxicology Report',
  [ReportType.LastClientContact]: 'Last Client Contact',
  [ReportType.VisitStatus]: 'Visit Status Report',
  [ReportType.Utilization]: 'Utilization Report',
  [ReportType.Click]: 'Click Report',
  [ReportType.LocationSharing]: 'User Location Sharing Settings',
};

export const reportTypeOptions = Object.entries(reportTypes).map(([key, label]) => ({
  value: key as ReportType,
  label,
}));

export const onlyAdminReports = [
  ReportType.MedicaIdRecertification,
  ReportType.VisitMetrics,
  ReportType.ClientStatus,
  ReportType.VisitsByTeam,
  ReportType.VisitsByClient,
  ReportType.ClientAllocationVisits,
  ReportType.Demographics,
  ReportType.ClientLengthOfStay,
  ReportType.LocationGroup,
  ReportType.LastClientContact,
  ReportType.VisitStatus,
  ReportType.Utilization,
  ReportType.Click,
  ReportType.LocationSharing,
];
