import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetLocationSharingOverviewReport } from '../types';

const onGetLocationSharingOverviewReport: IThunkGetLocationSharingOverviewReport = thunk(
  async (
    { setLocationSharingOverviewReport, setLocationSharingReportFilters }: Actions<IReportsActions>,
    { requestInfo, requestPayload },
  ) => {
    const locationSharingReport = await api.reports
      .getLocationSharingOverviewReport(requestInfo, requestPayload)
      .then(r => r.data);

    setLocationSharingOverviewReport({
      items: locationSharingReport.items,
      pagination: {
        total: locationSharingReport.total,
        pageNumber: requestPayload.pagination.pageNumber,
        pageSize: requestPayload.pagination.pageSize,
      },
    });

    setLocationSharingReportFilters({
      sorting: requestPayload.sorting,
      sortDirection: requestPayload.sortDirection,
    });
  },
);

export default onGetLocationSharingOverviewReport;
