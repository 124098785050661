import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetClickOverviewReport } from '../types';

const onGetClickOverviewReport: IThunkGetClickOverviewReport = thunk(
  async ({ setClickOverviewReport }: Actions<IReportsActions>, { requestInfo, requestPayload }) => {
    const clickReport = await api.reports
      .getClickOverviewReport(requestInfo, requestPayload)
      .then(r => r.data);

    setClickOverviewReport({
      items: clickReport.items,
      pagination: {
        total: clickReport.total,
        pageNumber: requestPayload.pagination.pageNumber,
        pageSize: requestPayload.pagination.pageSize,
      },
    });
  },
);

export default onGetClickOverviewReport;
