import { AccordionDetails, Grid, Typography } from '@material-ui/core';
import { FC, ReactElement } from 'react';

import { IClientOption } from '~/store/client/types';
import { AllocationMode } from '../../../../constants/allocationMode';
import AllocationMemberClientForm from './AllocationMemberClientForm';

import styles from '../../AccordionMembers.module.scss';
import AccordionMemberGroupForm from './AccordionMemberGroupForm';

interface IProps {
  allocationMode: AllocationMode;
  selectedClients: IClientOption[];
  selectedClientsByLocationGroup: Record<string, IClientOption[]>;
  renderSelect: () => ReactElement;
  handleRemoveClient: (id: number, field: string) => void;
  formFieldName: string;
  watchedClients: number[];
  handleRemoveClientInLocationGroup: (id: number, field: string, groupName?: string) => void;
}

const AccordionMembersDetailsFields: FC<IProps> = ({
  allocationMode,
  selectedClientsByLocationGroup,
  selectedClients,
  renderSelect,
  handleRemoveClient,
  handleRemoveClientInLocationGroup,
  formFieldName,
  watchedClients,
}) => {
  const renderClients = () => {
    if (allocationMode === AllocationMode.BY_LOCATION_GROUP) {
      return (
        <AccordionMemberGroupForm
          selectedClientsByLocationGroup={selectedClientsByLocationGroup}
          handleRemoveClientInLocationGroup={handleRemoveClientInLocationGroup}
          watchedClients={watchedClients}
          formFieldName={formFieldName}
        />
      );
    }

    return selectedClients.map(client => (
      <AllocationMemberClientForm
        key={client.id}
        clientName={client.name}
        clientPhoto={client.photo}
        clientId={client.id}
        fieldNameMemberId={formFieldName}
        handleRemoveClient={handleRemoveClient}
      />
    ));
  };

  return (
    <AccordionDetails>
      <Grid container>
        <Grid item sm={12} className={styles.accordionDetailsContainer}>
          {renderSelect()}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={12}>
          {selectedClients.length ? (
            <Typography variant="subtitle1" className={styles.assignedClientsTitle}>
              Assigned Clients
            </Typography>
          ) : null}
          {renderClients()}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
};

export default AccordionMembersDetailsFields;
