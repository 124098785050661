export const requiredField = 'This field is required.';
export const requiredRole = 'Please, select a role to sign in';
export const requiredFieldIfNoAddress = 'This field is required if Address 1 is empty.';
export const phoneInvalid = 'Invalid phone number. Use format 000-000-0000.';
export const zipCodeInvalid = 'Zip code should be exactly 5 characters';
export const fieldMax = 'The field must be a string with a maximum length of 100.';
export const fieldMaxTen = 'The field must be a string with a maximum length of 10.';
export const zeroToOneNumbers = 'The field should be between 0 and 1.';
export const fieldOnlyNumbers = 'The field should have digits only.';
export const fieldOnlyNumbersUpTo999 = 'The field should be between 0 and 999.';
export const validEmail = 'Please enter a valid email address';
export const rangeInvalid = 'Date range must have exactly two values';
