import { action } from 'easy-peasy';
import { setClickReportFilter } from '~/services/localStorage/filters';

import { IActionSetClickReportFilters } from '../types';

const setClickReportFilters: IActionSetClickReportFilters = action((state, payload) => {
  // save filters to storage
  setClickReportFilter(payload);

  state.click.filters = payload;
});

export default setClickReportFilters;
