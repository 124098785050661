import { action } from 'easy-peasy';

import { IActionSetClickOverviewReport } from '../types';

const setClickOverviewReport: IActionSetClickOverviewReport = action((state, payload) => {
  const { items, pagination } = payload;

  state.click.list = pagination.pageNumber === 1 ? items : [...state.click.list, ...items];
  state.click.pagination = pagination;
});

export default setClickOverviewReport;
