import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientAllocationActions, IThunkDeleteOwnClientAllocationGU } from '../types';

const onDeleteOwnClientAllocationGU: IThunkDeleteOwnClientAllocationGU = thunk(
  async (_actions: Actions<IClientAllocationActions>, { clinicId, clientAllocationId }) => {
    await api.clientAllocation.deleteOwnClientAllocationGU({
      clinicId,
      clientAllocationId,
    });
  },
);

export default onDeleteOwnClientAllocationGU;
