const isUserInDST = (date: Date): boolean => {
  const winter = new Date(date.getFullYear(), 0, 1);

  return date.getTimezoneOffset() < winter.getTimezoneOffset();
};

const adjustDatesToInitialDST = (startDate: string | null, currentDate: string): Date => {
  const date = new Date(currentDate);

  if (!startDate) {
    return date;
  }

  const start = new Date(startDate);

  const initialDST = isUserInDST(start);
  const currentDST = isUserInDST(date);

  if (currentDST !== initialDST) {
    date.setHours(date.getHours() + (initialDST ? 1 : -1));
  }

  return date;
};

export default adjustDatesToInitialDST;
