import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { ICommonRequestInfo } from './types';
import {
  ILocationSharingReportItem,
  ILocationSharingReportOverviewParams,
} from './types/locationSharingReportTypes';

export const getLocationSharingOverviewReport = (
  { clinicId }: ICommonRequestInfo,
  data: ILocationSharingReportOverviewParams,
): IResponse<{
  total: number;
  items: ILocationSharingReportItem[];
}> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/location-sharing/report`,
    data,
  });

export const generateLocationSharingReport = (
  { clinicId }: ICommonRequestInfo,
  data: Omit<ILocationSharingReportOverviewParams, 'pagination'>,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/location-sharing/report/export`,
    responseType: 'blob',
    data,
  });
