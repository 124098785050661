import api from '~/services/api';
import { groupClientAllocationData } from '~/utils/clientAllocation';
import { extractErrorMessage } from '~/utils/error/error';

import { NETWORK } from '~/ui/constants/paths';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from '~/store/hooks';

import {
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';
import { delay, uniq } from 'lodash';
import { AllocationMode } from '../constants/allocationMode';
import extractArchivedClients from '../helpers/extractArchivedClients';
import extractFullName from '~/utils/text/extractFullName';
import excludeArchivedClients from '../helpers/excludeArchivedClients';
import { IUpdateClientAllocation } from '~/services/api/clientAllocation';
import { normalizeDateString } from '~/utils/date/date';
import { IClientOption } from '~/store/client/types';
import extractLocationGroupTeamIdsByClients from '../helpers/extractLocationGroupTeamIdsByClients';
import { getClientsFormFieldName, getMemoFormFieldName } from '../helpers/getters';

interface IUseEditClientAllocationProps {
  clinicId: string;
  isGlobalUser: boolean;
  isActTeamMember: boolean;
  clientAllocation: IClientAllocationDetailed[];
  actTeamId: string;
  current: IClientOption[];
  locationGroups: IAllocationClientsByGroup[];
}

const useEditClientAllocation = ({
  clinicId,
  isGlobalUser,
  isActTeamMember,
  clientAllocation,
  actTeamId,
  current,
  locationGroups,
}: IUseEditClientAllocationProps): any => {
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const navigate = useNavigate();

  // global user has different endpoints and can create client allocations for any client in the clinic
  // at the same time global user has permissions as a team member, so can manage only own allocations
  const handleGlobalUserAllocations = async (
    allocation: IUpdateClientAllocation[],
    mode: AllocationMode,
    values: Record<string, any>,
  ) => {
    const allocationTeams = clientAllocation?.map(item => String(item.team.id));
    let selectedTeams = current.reduce((acc: { [key: number]: IClientOption[] }, obj) => {
      acc[obj.team.id] = [...(acc[obj.team.id] || []), obj];

      return acc;
    }, {});

    if (mode === AllocationMode.BY_LOCATION_GROUP) {
      const selectedClientIds = allocation?.flatMap(a => a.clients.map(c => c.id));
      selectedTeams = extractLocationGroupTeamIdsByClients(locationGroups, selectedClientIds);
    }

    const allocationsForDeleteGU = clientAllocation
      .filter(item => !selectedTeams[item.team.id])
      ?.map(item => String(item.id));
    const allocationTeamsForAddGU = Object.keys(selectedTeams).filter(
      key => !clientAllocation.find(a => a.team.id === Number(key)),
    );

    const allocationForEditGU = Object.keys(selectedTeams).filter(item =>
      allocationTeams.includes(item),
    );

    if (
      !allocationTeamsForAddGU.length &&
      !allocationsForDeleteGU.length &&
      !allocationForEditGU.length
    ) {
      showError('Please provide at least one client');
      return;
    }

    if (allocationsForDeleteGU.length) {
      await Promise.all(
        allocationsForDeleteGU?.map(clientAllocationId =>
          api.clientAllocation.deleteOwnClientAllocationGU({
            clinicId,
            clientAllocationId,
          }),
        ),
      );
    }

    const addPayload = {
      from: normalizeDateString(clientAllocation[0].date),
      to: normalizeDateString(clientAllocation[0].date, true),
      includeWeekends: true,
      locationGroupIds: allocation?.flatMap(item => item.locationGroupIds),
    };

    if (allocationTeamsForAddGU.length) {
      let clients = [];

      if (mode === AllocationMode.BY_CLIENT) {
        clients = current.filter(client =>
          allocationTeamsForAddGU.includes(String(client.team.id)),
        );
      } else {
        clients = allocation[0].clients;
      }

      if (clients.length) {
        await api.clientAllocation.addOwnClientAllocationGU(
          { clinicId },
          { ...addPayload, clients: allocation[0].clients },
        );
      }
    }

    if (allocationForEditGU.length) {
      await Promise.all(
        allocationForEditGU?.map(teamId => {
          const currentClientAllocation = clientAllocation.find(
            item => String(item.team.id) === teamId,
          );
          const allocationId = currentClientAllocation?.id;
          const memberId = currentClientAllocation?.user.id;
          const locationGroupIds = allocation
            .filter(item => item.id === String(allocationId))
            ?.flatMap(item => item.locationGroupIds);

          let clients = [];

          if (mode === AllocationMode.BY_CLIENT) {
            clients = current.filter(client => teamId === String(client.team.id));
          } else {
            clients = selectedTeams[Number(teamId)];
          }

          return api.clientAllocation.updateOwnClientAllocationGU(
            { clinicId, teamId: actTeamId, clientAllocationId: String(allocationId) },
            {
              ...addPayload,
              clients: clients.map(client => ({
                id: client.id,
                memo: values[
                  getMemoFormFieldName(
                    client.id,
                    // TODO: Rethink logic with incorrect clientAllocationId in prepareDefaultValues, so here found by incorrect allocationId, that is set in prepareDefaultValues
                    getClientsFormFieldName(memberId, clientAllocation[0]?.id),
                  ) || null
                ],
              })),
              locationGroupIds,
            },
          );
        }),
      );
    }

    showNotify({ message: 'Client allocation successfully updated' });
    navigate(NETWORK);
  };

  const onSubmit = async ({
    name,
    allocationMode,
    ...formValues
  }: { name: string; allocationMode: AllocationMode } & unknown) => {
    try {
      const archivedClients = extractArchivedClients(clientAllocation);

      if (archivedClients.length) {
        const archivedClientsNames = uniq(
          archivedClients.map(client => extractFullName(client)),
        ).join(', ');
        const isMultipleArchived = archivedClients.length > 1;
        showError(
          `The archived client${isMultipleArchived ? 's' : ''} ${archivedClientsNames} ha${
            isMultipleArchived ? 've' : 's'
          } been removed from the created allocation`,
        );
      }

      delay(
        async () => {
          const {
            allocationsForAdd,
            allocationsForDelete,
            allocationsForEdit: allocationsForEditWithArchivedClients,
          } = groupClientAllocationData(formValues);

          const allocationsForEdit = excludeArchivedClients<IUpdateClientAllocation>(
            allocationsForEditWithArchivedClients,
            archivedClients,
          );

          const editPayload = {
            allocations: allocationsForEdit,
          };

          if (
            !allocationsForAdd.length &&
            !allocationsForDelete.length &&
            !allocationsForEdit.length
          ) {
            showError('Please provide at least one client');
            return;
          }

          // add allocations
          const addPayload = {
            from: normalizeDateString(clientAllocation[0].date),
            to: normalizeDateString(clientAllocation[0].date, true),
            includeWeekends: true,
            allocations: allocationsForAdd,
          };

          if (allocationsForAdd.length && !isGlobalUser) {
            await api.clientAllocation.addClientAllocation(
              { clinicId, teamId: actTeamId },
              addPayload,
            );
          }

          // edit allocations
          if (allocationsForEdit.length) {
            if (isGlobalUser) {
              // as global user can edit only own allocations (like team member), there won't be anything except allocationsForEdit
              await handleGlobalUserAllocations(allocationsForEdit, allocationMode, formValues);
              return;
            }
            if (isActTeamMember) {
              await api.clientAllocation.updateOwnClientAllocation(
                { clinicId, teamId: actTeamId, clientAllocationId: allocationsForEdit[0].id },
                {
                  ...addPayload,
                  clients: allocationsForEdit[0].clients,
                  locationGroupIds: allocationsForEdit[0].locationGroupIds,
                },
              );
            } else {
              await api.clientAllocation.updateClientAllocation(
                { clinicId, teamId: actTeamId },
                editPayload,
              );
            }
          }

          // delete allocations
          if (allocationsForDelete.length) {
            if (isActTeamMember || isGlobalUser) {
              const removeMethod = isGlobalUser
                ? api.clientAllocation.deleteOwnClientAllocationGU
                : api.clientAllocation.deleteOwnClientAllocation;

              await removeMethod({
                clinicId,
                teamId: actTeamId,
                clientAllocationId: String(allocationsForDelete[0]),
              });
            } else {
              await Promise.all(
                allocationsForDelete.map(async id => {
                  api.clientAllocation.deleteClientAllocation({
                    clinicId,
                    teamId: actTeamId,
                    clientAllocationId: String(id),
                  });
                }),
              );
            }
          }
          if (isGlobalUser) {
            showNotify({ message: 'Client allocation successfully deleted' });
          } else {
            showNotify({ message: 'Client allocation successfully updated' });
          }
          navigate(NETWORK);
        },
        archivedClients.length ? 3000 : 0,
      );
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return onSubmit;
};

export default useEditClientAllocation;
