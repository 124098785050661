import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { ICommonRequestInfo } from './types';
import { IClickReportItem, IClickReportOverviewParams } from './types/clickReportTypes';

export const getClickOverviewReport = (
  { clinicId }: ICommonRequestInfo,
  data: IClickReportOverviewParams,
): IResponse<{
  total: number;
  items: IClickReportItem[];
}> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/click/report`,
    data,
  });

export const generateClickReport = (
  { clinicId }: ICommonRequestInfo,
  data: Omit<IClickReportOverviewParams, 'pagination'>,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/click/report/export`,
    responseType: 'blob',
    data,
  });
