import { IAddClientAllocationPayload } from '~/services/api/clientAllocation/types';
import { useStoreActions } from '~/store/hooks';

interface IProps {
  isGlobalUser: boolean;
  isActTeamMember: boolean;
}

type ReturnTypeUserAllClientAllocation = (payload: IAddClientAllocationPayload) => Promise<void>;

const useAddClientAllocationRequest = ({
  isGlobalUser,
  isActTeamMember,
}: IProps): ReturnTypeUserAllClientAllocation => {
  const onAddClientAllocation = useStoreActions(
    actions => actions.clientAllocation.onAddClientAllocation,
  );
  const onAddOwnClientAllocation = useStoreActions(
    actions => actions.clientAllocation.onAddOwnClientAllocation,
  );
  const onAddOwnClientAllocationGU = useStoreActions(
    actions => actions.clientAllocation.onAddOwnClientAllocationGU,
  );

  const addClientAllocationRequest = async ({
    clinicId,
    teamId,
    payload,
  }: IAddClientAllocationPayload) => {
    if (isGlobalUser) {
      await onAddOwnClientAllocationGU({ clinicId, teamId, payload });
      return;
    }

    if (isActTeamMember) {
      await onAddOwnClientAllocation({ clinicId, teamId, payload });
      return;
    }

    await onAddClientAllocation({ clinicId, teamId, payload });
  };

  return addClientAllocationRequest;
};

export default useAddClientAllocationRequest;
