import { action } from 'easy-peasy';

import { IActionSetLocationSharingOverviewReport } from '../types';

const setLocationSharingOverviewReport: IActionSetLocationSharingOverviewReport = action(
  (state, payload) => {
    const { items, pagination } = payload;

    state.locationSharing.list =
      pagination.pageNumber === 1 ? items : [...state.locationSharing.list, ...items];
    state.locationSharing.pagination = pagination;
  },
);

export default setLocationSharingOverviewReport;
