import { getUtilizationData } from '~/services/localStorage/utilization';
import { IUtilizationStore } from './types';

const initStore: IUtilizationStore = {
  loading: false,
  users: getUtilizationData() || {},
  currentModule: null,
  user: null,
};

export default initStore;
