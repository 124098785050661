import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import {
  clientAllocationMapper,
  clientMapper,
  sortByName,
  teamMemberMapper,
} from '~/utils/clientAllocation';
import { extractErrorMessage } from '~/utils/error/error';
import Form from './Form';

import { IClientAllocationDetailed } from '~/services/api/clientAllocation/types';
import { NETWORK, VIEW_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import extractDateFromString from '~/utils/date/extractDateFromString';
import extractFullName from '~/utils/text/extractFullName';
import { IOption } from '~/types';

type IParams = {
  actTeamId: string;
  clientAllocationDate: string;
};

const EditClientAllocation = (): ReactElement => {
  const { actTeamId, clientAllocationDate } = useParams<IParams>();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [clients, setClients] = useState<IOption[]>([]);
  const [assignedClients, setAssignedClients] = useState<number[]>([]);

  const {
    clinic: userClinic,
    id: userId,
    photo,
    ...user
  } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const clientAllocation = useStoreState(state => state.clientAllocation.current);
  const locationGroups = useStoreState(state => state.clientAllocation.locationGroups);
  const { showError } = useStoreActions(actions => actions.snackbar);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { onGetClinicActTeam, onGetActTeamsAvailable } = useStoreActions(
    actions => actions.actTeam,
  );
  const {
    onGetClientAllocation,
    onGetOwnClientAllocation,
    onGetOwnClientAllocationGU,
    onGetAllocationClientsByGroupGU,
    onGetAllocationClientsByGroup,
  } = useStoreActions(actions => actions.clientAllocation);

  const { isActTeam, isActTeamMember, isGlobalUser } = useRole();

  const userFullName = useMemo(() => extractFullName(user), [user]);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };

      const allocationDate = `${clientAllocationDate}T00:00:00Z`;

      await onGetMyClinic();

      if (isGlobalUser) {
        await onGetActTeamsAvailable({ clinicId: String(userClinic.id) });
      } else {
        await onGetClinicActTeam(payload);
      }

      if (isGlobalUser) {
        const availableTeamMembers = [
          { id: userId, fullName: userFullName, photo, isDeleted: false },
        ];
        setTeamMembers(availableTeamMembers);
      } else {
        await api.clientAllocation
          .getRelatedClients(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(data => data.map(clientMapper))
          .then(r => setClients(r));

        await api.actTeamMember
          .getActTeamMemberList(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(data => data.sort(sortByName).map(teamMemberMapper))
          .then(r => {
            if (isActTeamMember || isGlobalUser) {
              setTeamMembers(r.filter(member => member.id === userId));
            } else {
              setTeamMembers(r);
            }
          });
      }

      if (isGlobalUser) {
        await onGetOwnClientAllocationGU({
          clientAllocationDate: allocationDate,
        });
      } else if (isActTeamMember) {
        await onGetOwnClientAllocation({
          clientAllocationDate: allocationDate,
        });
      } else {
        const allocations: IClientAllocationDetailed[] = await onGetClientAllocation({
          clinicId: String(userClinic.id),
          teamId: actTeamId,
          clientAllocationDate: allocationDate,
        });

        setAssignedClients(clientAllocationMapper(allocations));
      }

      if (isGlobalUser) {
        await onGetAllocationClientsByGroupGU({ clinicId: String(userClinic.id) });
      } else {
        await onGetAllocationClientsByGroup({ clinicId: String(userClinic.id), teamId: actTeamId });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    actTeamId,
    clientAllocationDate,
    isActTeamMember,
    isGlobalUser,
    onGetAllocationClientsByGroup,
    onGetAllocationClientsByGroupGU,
    onGetOwnClientAllocationGU,
    onGetClientAllocation,
    onGetClinicActTeam,
    onGetActTeamsAvailable,
    userFullName,
    photo,
    onGetMyClinic,
    onGetOwnClientAllocation,
    showError,
    userClinic.id,
    userId,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || (!actTeam && !isGlobalUser) || !clientAllocation) return <Loader />;

  const breadcrumbItems = [
    ...(isGlobalUser ? [{ to: NETWORK, title: `${userClinic.name} Teams` }] : []),
    ...(isActTeam
      ? [
          {
            to: {
              pathname: VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                ':clientAllocationDate',
                String(clientAllocation[0].date.split('T')[0]),
              ),
            },
            title: 'Client Allocation',
          },
        ]
      : []),
    ...(!isActTeam && !isGlobalUser ? [{ to: NETWORK, title: `${userClinic.name} Teams` }] : []),
    { to: pathname, title: 'Edit Client Allocation' },
  ];

  return (
    <div>
      <h2>{`Edit Client Allocation | ${extractDateFromString(clientAllocation[0].date)}`}</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        clientAllocation={clientAllocation}
        assignedClients={assignedClients}
        teamMembers={teamMembers}
        clients={clients}
        actTeamId={actTeamId}
        clinicId={String(userClinic.id)}
        locationGroups={locationGroups}
      />
    </div>
  );
};

export default EditClientAllocation;
