import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IUtilizationModel } from './types';

const utilization: IUtilizationModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default utilization;
