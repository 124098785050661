import * as paths from '~/ui/constants/paths';
import UtilizationModule from './utilizationModule';

const {
  AI_MANAGEMENT,
  RESOURCE,
  ADD_CLIENT,
  ADD_CLIENT_ALLOCATION,
  ADD_MY_CLIENT_ALLOCATION,
  CLIENT,
  DUPLICATE_CLIENT_ALLOCATION,
  DUPLICATE_MY_CLIENT_ALLOCATION,
  EDIT_CLIENT,
  EDIT_CLIENT_ALLOCATION,
  EDIT_MY_CLIENT_ALLOCATION,
  EDIT_PROFILE,
  MY_CLIENTS,
  PROFILE,
  VIEW_CLIENT,
  VIEW_CLIENT_ALLOCATION,
  VIEW_MY_CLIENT_ALLOCATION,
  ADD_VISIT,
  COORDINATE,
  EDIT_VISIT,
  MEDICATION_LOGISTICS,
  NETWORK,
  PREVIEW_VISIT,
  REPORTS,
  VISITS,
} = paths;

const aiModule = [AI_MANAGEMENT];

const resourcesModule = [RESOURCE];

const userProfileModule = [PROFILE, EDIT_PROFILE];
const clientsModule = [MY_CLIENTS, CLIENT, ADD_CLIENT, EDIT_CLIENT, VIEW_CLIENT];

const clientAllocationsModule = [
  ADD_CLIENT_ALLOCATION,
  VIEW_CLIENT_ALLOCATION,
  EDIT_CLIENT_ALLOCATION,
  DUPLICATE_CLIENT_ALLOCATION,
  ADD_MY_CLIENT_ALLOCATION,
  VIEW_MY_CLIENT_ALLOCATION,
  EDIT_MY_CLIENT_ALLOCATION,
  DUPLICATE_MY_CLIENT_ALLOCATION,
];

const medicationLogisticsModule = [MEDICATION_LOGISTICS];

const coordinateModule = [COORDINATE];
const visitsModule = [VISITS, ADD_VISIT, EDIT_VISIT, PREVIEW_VISIT];
const reportsModule = [REPORTS];
const networkModule = [NETWORK];

const moduleMap = {
  [UtilizationModule.network]: networkModule,
  [UtilizationModule.coordinate]: coordinateModule,
  [UtilizationModule.visits]: visitsModule,
  [UtilizationModule.medicationLogistics]: medicationLogisticsModule,
  [UtilizationModule.clientAllocation]: clientAllocationsModule,
  [UtilizationModule.ai]: aiModule,
  [UtilizationModule.resources]: resourcesModule,
  [UtilizationModule.userProfile]: userProfileModule,
  [UtilizationModule.clients]: clientsModule,
  [UtilizationModule.reports]: reportsModule,
};

const getRouteToModuleMap = (
  map: Record<UtilizationModule, string[]>,
): Partial<Record<string, UtilizationModule | undefined>> => {
  const routeToModule: Partial<Record<string, UtilizationModule | undefined>> = {};

  Object.entries(map).forEach(([module, routes]) => {
    routes.forEach(route => {
      const normalizedRoute = route
        .split('/')
        .filter(item => !item.includes(':'))
        .join('/'); // remove url parameters e.g. :teamId to detect currently opened page/module
      routeToModule[normalizedRoute] = module as UtilizationModule;
    });
  });

  return routeToModule;
};

const routeToModuleMap = getRouteToModuleMap(moduleMap);

export default routeToModuleMap;
