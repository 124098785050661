import { Grid, IconButton, Typography } from '@material-ui/core';
import { FC, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

import { ReactComponent as DeleteIcon } from '~/ui/assets/images/delete.svg';
import smallAvatarIcon from '~/ui/assets/images/smallAvatar.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import PrivateImage from '~/ui/components/common/PrivateImage';
import Input from '~/ui/components/inputs/Input';
import { getMemoFormFieldName } from '../../../../helpers/getters';
import styles from '../../AccordionMembers.module.scss';

interface IProps {
  clientName: string;
  clientPhoto: string | null;
  clientId: number | string;
  fieldNameMemberId: string;
  handleRemoveClient: (id: number, field: string) => void;
}

const AllocationMemberClientForm: FC<IProps> = ({
  clientName,
  clientPhoto,
  clientId,
  fieldNameMemberId,
  handleRemoveClient,
}): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container key={clientId} alignItems="center" className={styles.clientDetailItem}>
      <Grid item xs={4} className={styles.selectedClient}>
        <PrivateImage
          className={styles.image}
          src={clientPhoto || smallAvatarIcon}
          height={40}
          alt="avatar"
        />
        <Typography>{clientName}</Typography>
      </Grid>
      <Grid item xs={8} className={styles.memoContainer}>
        <Input
          tabIndex={-1}
          register={register}
          errors={errors}
          name={getMemoFormFieldName(clientId as number, fieldNameMemberId)}
          label="Memo"
        />
        <IconButton
          onClick={() => handleRemoveClient(clientId as number, `clients${fieldNameMemberId}`)}
        >
          <DeleteIcon width={20} color={variables.colorRed} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AllocationMemberClientForm;
