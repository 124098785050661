// auth
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SELECT_ROLE = '/select-role';
export const FORGOT_PASSWORD = '/forgot-password';
export const CHANGE_PASSWORD = '/change-password';

// clinic management
export const CLINIC_MANAGEMENT = '/clinic-management';
export const MY_CLINIC = '/my-clinic';
export const ADD_CLINIC = `${CLINIC_MANAGEMENT}/add-clinic`;
export const EDIT_CLINIC = `${CLINIC_MANAGEMENT}/edit-clinic/:id`;
export const VIEW_CLINIC = `${CLINIC_MANAGEMENT}/view-clinic/:id`;

// ai management
export const AI_MANAGEMENT = '/ai-management';

// release management
export const RELEASE_MANAGEMENT = '/release-management';
export const ADD_RELEASE = `${RELEASE_MANAGEMENT}/add-release`;
export const EDIT_RELEASE = `${RELEASE_MANAGEMENT}/edit-release/:id`;

// organizations
export const ORGANIZATION_MANAGEMENT = '/organization';

// resourses
export const RESOURCE = '/resource';

// dictionaries
export const DICTIONARIES = '/dictionaries';

// teams
export const CLINIC_VIEW_ACT_TEAM = `${CLINIC_MANAGEMENT}/view-clinic/:clinicId/view-act-team/:actTeamId`;
export const CLINIC_EDIT_ACT_TEAM = `${CLINIC_MANAGEMENT}/view-clinic/:clinicId/edit-act-team/:actTeamId`;
export const CLINIC_CREATE_ACT_TEAM = `${CLINIC_MANAGEMENT}/view-clinic/:clinicId/create-act-team`;
export const ACT_TEAM_MANAGEMENT = '/act-team';
export const VIEW_ACT_TEAM = `${ACT_TEAM_MANAGEMENT}/:actTeamId`;
export const ADD_ACT_TEAM = `${ACT_TEAM_MANAGEMENT}/add-act-team`;
export const MY_ACT_TEAM = '/my-act-team';

// local admins (LA)
export const CLINIC_ADD_LOCAL_ADMIN = `${VIEW_CLINIC}/add-local-admin`;
export const CLINIC_EDIT_LOCAL_ADMIN = `${VIEW_CLINIC}/edit-local-admin/:localAdminId`;
export const CLINIC_VIEW_LOCAL_ADMIN = `${VIEW_CLINIC}/view-local-admin/:localAdminId`;

// alerts
export const SYSTEM_ALERTS_MANAGEMENT = '/system-alerts-management';
export const ADD_SYSTEM_ALERT = `${SYSTEM_ALERTS_MANAGEMENT}/add-system-alert`;

// team members (TM/TL)
export const EDIT_TEAM_MEMBER = `${VIEW_ACT_TEAM}/edit-team-member/:teamMemberId`;
export const VIEW_ACT_TEAM_MEMBER = `${VIEW_ACT_TEAM}/view-team-member/:teamMemberId`;
export const ADD_TEAM_MEMBER = `${VIEW_ACT_TEAM}/add-team-member`;

// notifications
export const NOTIFICATIONS = '/notifications';

// profile
export const PROFILE = '/profile';
export const EDIT_PROFILE = `${PROFILE}/edit`;

// clients
export const MY_CLIENTS = '/my-clients';
export const CLIENT = '/client';
export const ADD_CLIENT = `${CLIENT}/add`;
export const EDIT_CLIENT = `${CLIENT}/:clientId/act-team/:actTeamId/edit`;
export const VIEW_CLIENT = `${CLIENT}/:clientId/act-team/:actTeamId/view`;

// client allocations
export const ADD_CLIENT_ALLOCATION = `${VIEW_ACT_TEAM}/client-allocation/add`;
export const VIEW_CLIENT_ALLOCATION = `${VIEW_ACT_TEAM}/client-allocation/:clientAllocationDate/view`;
export const EDIT_CLIENT_ALLOCATION = `${VIEW_ACT_TEAM}/client-allocation/:clientAllocationDate/edit`;
export const DUPLICATE_CLIENT_ALLOCATION = `${VIEW_ACT_TEAM}/client-allocation/:clientAllocationDate/duplicate`;
export const ADD_MY_CLIENT_ALLOCATION = '/my-client-allocation/add';
export const VIEW_MY_CLIENT_ALLOCATION = '/my-client-allocation/:clientAllocationDate/view';
export const EDIT_MY_CLIENT_ALLOCATION = '/my-client-allocation/:clientAllocationDate/edit';
export const DUPLICATE_MY_CLIENT_ALLOCATION =
  '/my-client-allocation/:clientAllocationDate/duplicate';

// program assistants (PA)
export const CLINIC_ADD_PROGRAM_ASSISTANT = `${VIEW_CLINIC}/add-program-assistant`;
export const CLINIC_EDIT_PROGRAM_ASSISTANT = `${VIEW_CLINIC}/edit-program-assistant/:programAssistantId`;
export const CLINIC_VIEW_PROGRAM_ASSISTANT = `${VIEW_CLINIC}/view-program-assistant/:programAssistantId`;

// super admin
export const SUPER_ADMIN = '/super-admin';
export const ADD_SUPER_ADMIN = '/super-admin/add';
export const EDIT_SUPER_ADMIN = '/super-admin/:superAdminId/edit';

// medication logistics
export const MEDICATION_LOGISTICS = '/medication-logistics';

// chats
export const COORDINATE = '/coordinate';

// visits
export const VISITS = '/visits';
export const ADD_VISIT = '/visits/add';
export const EDIT_VISIT = '/visits/edit/:visitId';
export const PREVIEW_VISIT = '/visits/preview/:visitId';

// reports
export const REPORTS = '/reports';

// network/map
export const NETWORK = '/network';

// other
export const FRESH_DESK_SUPPORT = 'https://vellohealth.freshdesk.com/support/home';
