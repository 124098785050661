import { action } from 'easy-peasy';

import { storeUtilizationData } from '~/services/localStorage/utilization';

import { IActionSetUtilizationUsers } from '../types';

const setUtilizationUsers: IActionSetUtilizationUsers = action((state, payload) => {
  state.users = payload;
  storeUtilizationData(payload);
});

export default setUtilizationUsers;
