import { useCallback } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { IClientOption } from '~/store/client/types';
import {
  IAllocationClient,
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';
import { IClient } from '~/services/api/client/types';

interface IUseSelectedClientsData {
  getSelectedClients: (clientAllocations: IClientOption[]) => IClientOption[];
  getClientsByLocationGroup: (
    locationGroups: IAllocationClientsByGroup[],
    selectedGroups: number[],
  ) => Record<string, IClientOption[]>;
  getClientsByClientAllocation: (
    clientAllocations: IClientAllocationDetailed[],
  ) => IAllocationClient[];
  selectedClientsIds: number[];
  selectedGroupsIds: number[];
  getSelectedFieldsByName: (name: string, fieldNameWithMemberId: string) => FieldValues;
}

const useSelectedClientsData = (formFieldName: string): IUseSelectedClientsData => {
  const { watch } = useFormContext();

  const selectedGroupsIds = watch(`groups${formFieldName}`);
  const selectedClientsIds = watch(`clients${formFieldName}`);

  const getClientsByLocationGroup = useCallback(
    (
      locationGroups: IAllocationClientsByGroup[],
      selectedGroups: number[],
    ): Record<string, IClientOption[]> =>
      locationGroups.reduce((acc, currentGroup) => {
        if (!selectedGroupsIds?.includes(currentGroup.locationGroup.id)) {
          return acc;
        }
        const groupClients = currentGroup.clients.filter(client =>
          selectedClientsIds?.includes(client.id),
        );

        if (groupClients.length > 0 && selectedGroups.includes(currentGroup.locationGroup.id)) {
          return {
            ...acc,
            [currentGroup.locationGroup.name]: groupClients,
          };
        }

        return acc;
      }, {} as Record<string, IClientOption[]>),
    [selectedClientsIds, selectedGroupsIds],
  );

  const getClientsByClientAllocation = useCallback(
    (clientAllocations?: IClientAllocationDetailed[]): IAllocationClient[] =>
      (clientAllocations || []).reduce(
        (acc, clientAllocation) =>
          [...acc, ...clientAllocation.user.clients]
            .filter(client => (selectedClientsIds || []).includes(client.id))
            .map(c => ({
              name:
                c.firstName && c.lastName ? `${c.firstName} ${c.lastName}` : c.name || 'Unknown',
              id: c.id,
              photo: c.photo || null,
            })),
        [],
      ),
    [selectedClientsIds],
  );

  const getSelectedClients = useCallback(
    (clientsList?: (IClient | IClientOption)[]): IClientOption[] =>
      (clientsList || [])
        .filter(client => (selectedClientsIds || []).includes(client.id))
        .map(c => {
          const extendedClient = c as IClient;
          const clientOption = c as IClientOption;
          return {
            name:
              extendedClient.firstName && extendedClient.lastName
                ? `${extendedClient.firstName} ${extendedClient.lastName}`
                : clientOption.name || 'Unknown',
            id: c.id,
            photo: c.photo || null,
          };
        }),
    [selectedClientsIds],
  );

  const getSelectedFieldsByName = (name: string, fieldNameWithMemberId: string): FieldValues => {
    const formValues = watch();
    const formKeys = Object.keys(formValues);

    const selectedFields = formKeys.filter(
      key => key.startsWith(name) && `${name}${fieldNameWithMemberId}`.includes(key),
    );

    return formValues[selectedFields[0]];
  };

  return {
    selectedGroupsIds,
    selectedClientsIds,
    getClientsByLocationGroup,
    getSelectedClients,
    getClientsByClientAllocation,
    getSelectedFieldsByName,
  };
};

export default useSelectedClientsData;
