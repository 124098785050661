import { FC, memo } from 'react';
import { Typography } from '@material-ui/core';

import AllocationMemberClientForm from './AllocationMemberClientForm';
import { IClientOption } from '~/store/client/types';

import styles from '../../AccordionMembers.module.scss';

interface AccordionMemberGroupFormProps {
  watchedClients: number[];
  formFieldName: string;
  selectedClientsByLocationGroup: Record<string, IClientOption[]>;
  handleRemoveClientInLocationGroup: (id: number, field: string, groupName?: string) => void;
}

const AccordionMemberGroupForm: FC<AccordionMemberGroupFormProps> = ({
  selectedClientsByLocationGroup,
  handleRemoveClientInLocationGroup,
  watchedClients,
  formFieldName,
}) => {
  const removeClients = (id: number, groupName: string) => {
    handleRemoveClientInLocationGroup(id, formFieldName, groupName);
  };

  return (
    <>
      {Object.keys(selectedClientsByLocationGroup).map(groupName => (
        <div key={groupName}>
          <Typography variant="subtitle1" className={styles.assignedClientsTitle}>
            {groupName}
          </Typography>

          {selectedClientsByLocationGroup[groupName]
            .filter(client => watchedClients.includes(client.id))
            .map(client => (
              <AllocationMemberClientForm
                key={client.id}
                clientName={client.name}
                clientPhoto={client.photo}
                clientId={client.id}
                fieldNameMemberId={formFieldName}
                handleRemoveClient={id => removeClients(id, groupName)}
              />
            ))}
        </div>
      ))}
    </>
  );
};

export default memo(AccordionMemberGroupForm);
