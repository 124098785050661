import { PaperWorkStatus } from '~/services/api/clientDetails/types';
import {
  getClickReportFilter,
  getClientLastContactFilter,
  getClientLengthOfStayFilter,
  getClientStatusFilter,
  getClientTreatmentPlansFilter,
  getDashboardFilter,
  getDemographicsFilter,
  getLocationGroupFilter,
  getLocationSharingReportFilter,
  getMedicationsFilter,
  getRecertificationReportFilter,
  getTeamMemberDashboardFilter,
  getToxicologyFilter,
  getTreatmentPlansFilter,
  getUtilizationReportFilter,
  getVisitStatusReportFilter,
  getVisitsByFilter,
} from '~/services/localStorage/filters';
import ClientLastContactSortableColumns from '~/ui/pages/Reports/constants/clientLastContactSortableColumns';
import ClientStatusSortableColumns from '~/ui/pages/Reports/constants/clientStatusSortableColumns';
import SortableColumns from '~/ui/pages/Reports/constants/locationGroupSortableColumns';
import MedicationsSortOptions from '~/ui/pages/Reports/constants/medicationsOptions';
import ToxicologySortableColumns from '~/ui/pages/Reports/constants/toxicologySortableColumns';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import { IReportsStore } from './types';
import VisitStatusSortableColumns from '~/ui/pages/Reports/constants/visitStatusSortableColumns';
import LocationSharingSettingsSorting from './models/locationSharingSettingsSorting';

const initStore: IReportsStore = {
  recertification: {
    current: null,
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getRecertificationReportFilter() || {
      teamIds: [],
      reportDate: null,
      paperworkStatus: 'all' as PaperWorkStatus,
      orBy: null,
    },
  },
  visitsByTeam: {
    current: null,
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 15,
    },
    totals: {
      clientsCount: 0,
      clientsVisited: 0,
      completedVisits: 0,
      duration: 0,
      noShow: 0,
      completedBillableVisits: 0,
      completedNonBillableVisits: 0,
      billableDuration: 0,
      nonBillableDuration: 0,
    },
  },
  visitsByClient: {
    current: null,
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 15,
    },
    totals: {
      clientsCount: 0,
      clientsVisited: 0,
      completedVisits: 0,
      duration: 0,
      noShow: 0,
      completedBillableVisits: 0,
      completedNonBillableVisits: 0,
      billableDuration: 0,
      nonBillableDuration: 0,
    },
    filters: getVisitsByFilter() || {
      teamIds: [],
      clientIds: [],
      teamMemberIds: [],
    },
  },
  clientLengthOfStay: {
    current: null,
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 15,
    },
    totals: {
      census: 0,
      capacity: 0,
      referrals: 0,
      discharges: 0,
      aot: 0,
      highSr: 0,
      moderateSr: 0,
      lowSr: 0,
    },
    filters: getClientLengthOfStayFilter() || {
      teamIds: [],
      teamId: null,
      from: null,
      to: null,
      activeOnly: true,
    },
  },
  dashboard: {
    totals: {
      teams: 0,
      teamMembers: 0,
      uniqueTeamMembers: 0,
      census: 0,
      medicaidRecertifications: 0,
      medicaidExpired: 0,
      completedVisits: 0,
      visits: 0,
      capacity: 0,
    },
    teamsOverview: {
      totals: {
        teams: 0,
        activeTeamMembers: 0,
        clientCensus: 0,
        capacity: 0,
        medicaidRecertifications: 0,
        medicaidExpired: 0,
        currentMonthVisits: {
          completed: 0,
          noShow: 0,
        },
        currentWeekVisits: {
          completed: 0,
          noShow: 0,
        },
      },
      teamDetails: [],
      teamsOverview: [],
    },
    teamDetails: {
      employmentStatuses: [],
      subRoles: [],
      credentials: [],
      experiences: [],
    },
    clientDetails: {
      suicideRisks: [],
      genders: [],
      primaryDiagnoses: [],
      ageRanges: [],
      races: [],
      aot: 0,
      census: 0,
      capacity: 0,
    },
    medicaidDetails: {
      clientsNumber: 0,
      paperworkStatuses: [],
    },
    visitDetails: {
      currentMonthVisitDetails: {
        totalVisits: [],
        completedVisits: [],
        visitNumber: 0,
        billableVisitMinutes: 0,
        nonBillableVisitMinutes: 0,
        billableVisitHours: { minutes: 0, hours: 0 },
        nonBillableVisitHours: { minutes: 0, hours: 0 },
      },
      currentWeekVisitDetails: {
        totalVisits: [],
        completedVisits: [],
        visitNumber: 0,
        billableVisitMinutes: 0,
        nonBillableVisitMinutes: 0,
        billableVisitHours: { minutes: 0, hours: 0 },
        nonBillableVisitHours: { minutes: 0, hours: 0 },
      },
    },
    filters: getDashboardFilter() || {
      teamIds: [],
    },
  },
  teamMemberDashboard: {
    teamVisits: [],
    sosAlerts: 0,
    completedVisits: 0,
    noShowVisits: 0,
    billableVisits: 0,
    nonBillableVisits: 0,
    offSite: 0,
    onSite: 0,
    voiceCall: 0,
    videoCall: 0,
    hospital: 0,
    clientsSeen: 0,
    clientAllocations: 0,
    assignedTreatmentPlans: 0,
    filters: getTeamMemberDashboardFilter() || {
      teams: [],
    },
  },
  favoriteReports: [],
  clientAllocationVisits: {
    teams: [],
    filters: {
      clientIds: [],
      teamIds: [],
    },
  },
  demographics: {
    columns: [],
    report: {
      columns: [],
      teams: [],
    },
    filters: getDemographicsFilter() || {
      teamIds: [],
    },
  },
  medications: {
    report: [],
    filters: getMedicationsFilter() || {
      teamIds: [],
      injectable: { from: null, to: null },
      endDate: { from: null, to: null },
      sortDirection: SortDirection.Desc,
      sorting: MedicationsSortOptions.StartDate,
    },
  },
  treatmentPlans: {
    report: { total: 0, items: [], pageNumber: 1 },
    filters: getTreatmentPlansFilter() || {
      clientIds: [],
      teamIds: [],
    },
  },
  clientStatus: {
    report: { total: 0, items: [], pageNumber: 1 },
    filters: getClientStatusFilter() || {
      teamIds: [],
      status: null,
      sortDirection: SortDirection.Asc,
      sorting: ClientStatusSortableColumns.ClientName,
    },
  },
  clientTreatmentPlans: {
    effectiveDates: [],
    report: [],
    filters: getClientTreatmentPlansFilter() || {
      teamId: null,
      clientId: null,
      treatmentPlanIds: [],
    },
  },
  locationGroup: {
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getLocationGroupFilter() || {
      teamIds: [],
      sortDirection: SortDirection.Asc,
      sorting: SortableColumns.ClientName,
    },
  },
  toxicology: {
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getToxicologyFilter() || {
      teamIds: [],
      clientIds: [],
      sortDirection: SortDirection.Asc,
      sorting: ToxicologySortableColumns.ClientName,
    },
  },
  clientLastContact: {
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getClientLastContactFilter() || {
      teamIds: [],
      clientIds: [],
      sortDirection: SortDirection.Asc,
      sorting: ClientLastContactSortableColumns.ClientName,
    },
  },
  visitStatus: {
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getVisitStatusReportFilter() || {
      teamIds: [],
      statuses: [],
      sortDirection: SortDirection.Asc,
      sorting: VisitStatusSortableColumns.VisitDate,
    },
  },
  utilization: {
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getUtilizationReportFilter() || {
      teamIds: [],
      userIds: [],
      from: null,
      to: null,
    },
  },
  click: {
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getClickReportFilter() || {
      teamIds: [],
      userIds: [],
      from: null,
      to: null,
    },
  },
  locationSharing: {
    list: [],
    pagination: {
      total: 0,
      pageNumber: 1,
      pageSize: 25,
    },
    filters: getLocationSharingReportFilter() || {
      teamIds: [],
      userIds: [],
      from: null,
      to: null,
      sorting: LocationSharingSettingsSorting.TeamMember,
      sortDirection: SortDirection.Asc,
    },
  },
};

export default initStore;
