import { action } from 'easy-peasy';
import { setUtilizationReportFilter } from '~/services/localStorage/filters';

import { IActionSetUtilizationReportFilters } from '../types';

const setUtilizationReportFilters: IActionSetUtilizationReportFilters = action((state, payload) => {
  // save filters to storage
  setUtilizationReportFilter(payload);

  state.utilization.filters = payload;
});

export default setUtilizationReportFilters;
