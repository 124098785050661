import unionBy from 'lodash/unionBy';
import { IActTeamMemberAvailableList } from '~/services/api/actTeamMember/types';
import { IAddClientAllocation, IUpdateClientAllocation } from '~/services/api/clientAllocation';
import {
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
  IClientAllocationPayload,
  IRelatedClientsForAllocation,
} from '~/services/api/clientAllocation/types';
import { IRole } from '~/store/user/types';
import { IOption } from '~/types';
import {
  getClientsFormFieldName,
  getPayloadClients,
  getMemoFormFieldName,
} from '~/ui/pages/ActTeam/reusable/ClientAllocationForm/helpers/getters';

export interface ITeamMemberMapped {
  id: number;
  fullName: string;
  photo: string | null;
  isDeleted: boolean;
  roles?: IRole[];
}

interface IGroupedClientAllocationData {
  allocationsForEdit: IUpdateClientAllocation[];
  allocationsForAdd: IAddClientAllocation[];
  allocationsForDelete: number[];
}

export const formValuesMapper = (obj: { [key: string]: number[] }): number[] =>
  Object.keys(obj).reduce(
    (acc, val) =>
      val.startsWith('clients_') || val.startsWith('groups_') ? [...acc, ...(obj[val] || [])] : acc,
    [],
  );

export const groupClientAllocationData = (
  values: Record<string, unknown>,
  allAdd?: boolean,
): IGroupedClientAllocationData =>
  Object.keys(values)
    .filter(key => !key.includes('groups') && !key.includes('memo'))
    .reduce(
      (acc: IGroupedClientAllocationData, key: string) => {
        const keyArray = key.split('_');
        const userId = keyArray[1];
        const allocationId = keyArray[2];

        const locationGroupIds = Array.from(
          new Set([
            ...((values[`groups_${userId}${allocationId ? `_${allocationId}` : ''}`] as number[]) ||
              []),
          ]),
        ); // remove duplicates

        if (key.startsWith('clients')) {
          const item = values[key] as number[];
          const itemLength = item?.length;
          if (typeof itemLength === 'number' && !itemLength && allocationId) {
            // delete allocation
            acc.allocationsForDelete.push(Number(allocationId));
          } else if (allocationId && !allAdd) {
            // edit allocation
            acc.allocationsForEdit.push({
              id: allocationId,
              clients: getPayloadClients(item, values, Number(userId), Number(allocationId)),
              locationGroupIds,
            });
          } else if (itemLength) {
            // add allocation
            acc.allocationsForAdd.push({
              userId: Number(userId),
              clients: getPayloadClients(item, values, Number(userId), Number(allocationId)),
              locationGroupIds,
            });
          }
        }

        return acc;
      },
      { allocationsForEdit: [], allocationsForAdd: [], allocationsForDelete: [] },
    );

export function mergeAllocationByUserId(
  clientAllocations: IClientAllocationDetailed[],
): IClientAllocationDetailed[] {
  const mergedArray: IClientAllocationDetailed[] = [];

  clientAllocations.forEach(item => {
    // Find an existing entry with the same user ID
    const existing = mergedArray.find(i => i.user.id === item.user.id);

    if (existing) {
      // Merge clients and locationGroups
      existing.user = {
        ...existing.user,
        clients: unionBy(
          [...(existing.user.clients || []), ...(item.user.clients || [])].map(client => ({
            ...client,
          })),
          'id',
        ),
      };

      // Merge locationGroups, ensuring immutability
      existing.locationGroups = unionBy(
        [...(existing.locationGroups || []), ...(item.locationGroups || [])].map(group => ({
          ...group,
        })),
        'id',
      );
    } else {
      // Add a deep copy of the item to avoid reference issues
      mergedArray.push({
        ...item,
        user: {
          ...item.user,
          clients: item.user.clients.map(client => ({ ...client })),
        },
        locationGroups: item.locationGroups.map(group => ({ ...group })),
      });
    }
  });

  return mergedArray;
}

export const prepareDefaultValues = (
  clientAllocations: IClientAllocationDetailed[],
): { [key: string]: number[] } => {
  const usr = mergeAllocationByUserId(clientAllocations).reduce((acc, currentValue) => {
    if (currentValue.isArchived || !currentValue.user?.clients?.length) {
      return acc;
    }

    const getClientsField = () => ({
      [`clients_${currentValue.user.id}_${currentValue.id}`]: currentValue.user.clients.map(
        item => item.id,
      ),
    });

    const getMemoField = () =>
      currentValue.user?.clients?.reduce(
        (prevClient, client) => ({
          ...prevClient,
          [getMemoFormFieldName(
            client.id,
            getClientsFormFieldName(currentValue.user.id, currentValue.id),
          )]: client.memo,
        }),
        {} as Record<string, string>,
      );

    const getGroupsField = () => ({
      [`groups_${currentValue.user.id}_${currentValue.id}`]:
        currentValue.locationGroups?.map(item => item.id) || [],
    });

    return {
      ...acc,
      ...getClientsField(),
      ...getMemoField(),
      ...getGroupsField(),
    };
  }, {});

  return usr;
};

export const teamMemberMapper = (teamMember: IActTeamMemberAvailableList): ITeamMemberMapped => ({
  id: teamMember.id,
  fullName: `${teamMember.firstName} ${teamMember.lastName}`,
  photo: teamMember.photo,
  isDeleted: teamMember.isDeleted || !teamMember.isActive,
  roles: teamMember.roles,
});

export const clientMapper = (client: IRelatedClientsForAllocation): IOption => ({
  value: client.id,
  label: `${client.firstName} ${client.lastName}`,
  photo: client.photo,
  name: `${client.firstName} ${client.lastName}`,
  id: client.id,
});

export const formatLocationGroupOptions = (groups: IAllocationClientsByGroup[]): IOption[] =>
  (groups || []).map(group => ({
    value: group.locationGroup.id,
    label: group.locationGroup.name,
  }));

export const sortByName = (
  a: IActTeamMemberAvailableList,
  b: IActTeamMemberAvailableList,
): number => (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase() ? -1 : 1);

// need to extract all assigned clients ids
export const clientAllocationMapper = (clientAllocations: IClientAllocationDetailed[]): number[] =>
  clientAllocations
    .map(item => item.user.clients)
    .flat()
    .map(item => item.id);

interface IFormValues {
  includeWeekends: boolean;
  allocationMode: string;
  clientAllocations?: IClientAllocationDetailed[];
  dateRange: [string, string];
  [key: string]: unknown;
}

export const transformFormToPayload = (values: IFormValues): IClientAllocationPayload => {
  const allocations = Object.entries(values).reduce((acc, [key, value]) => {
    if (key.startsWith('clients_')) {
      const memberId = Number(key.split('_')[1]);
      const allocationId = Number(key.split('_')[2]);

      const activeClients = (value as number[]).filter(clientId => {
        const clientAllocation = values.clientAllocations?.find(ca =>
          ca.user.clients.some(c => c.id === clientId),
        );
        return !clientAllocation?.isArchived;
      });

      if (activeClients.length === 0) return acc;

      const clients = activeClients.map(clientId => ({
        id: clientId,
        memo:
          ((allocationId
            ? values[`memo_${clientId}_${memberId}_${allocationId}`]
            : values[`memo_${clientId}_${memberId}`]) as string) || '',
      }));

      const locationGroupIds = Array.from(
        new Set([
          ...((values[`groups_${memberId}${allocationId ? `_${allocationId}` : ''}`] as number[]) ||
            []),
        ]),
      ); // remove duplicates

      if (allocationId) {
        acc.push({
          userId: memberId,
          id: allocationId,
          locationGroupIds,
          clients,
        });

        return acc;
      }

      acc.push({
        userId: memberId,
        id: allocationId,
        locationGroupIds,
        clients,
      });
    }
    return acc;
  }, [] as IClientAllocationPayload['allocations']);
  return {
    from: values.dateRange[0],
    to: values.dateRange[1],
    includeWeekends: values.includeWeekends,
    allocations: allocations.filter(a => a.clients.length > 0),
  };
};
