import onGetRecertificationReports from './onGetRecertificationReports';
import onGetDashboardReport from './onGetDashboardReport';
import onGetVisitsByTeamReport from './onGetVisitsByTeamReport';
import onGetVisitsByClientReport from './onGetVisitsByClientReport';
import onGetVisitsListByTeamReport from './onGetVisitsListByTeamReport';
import onGetVisitsListByClientReport from './onGetVisitsListByClientReport';
import onGetFavoriteReports from './onGetFavoriteReports';
import onAddFavoriteReport from './onAddFavoriteReport';
import onRemoveFavoriteReport from './onRemoveFavoriteReport';
import onGetClientAllocationVisitsReport from './onGetClientAllocationVisitsReport';
import onGetDemographicsAvailableColumns from './onGetDemographicsAvailableColumns';
import onGetDemographicsReport from './onGetDemographicsReport';
import onGetDemographicsReportByTeam from './onGetDemographicsReportByTeam';
import onGetTreatmentPlansReport from './onGetTreatmentPlansReport';
import onGetClientStatusReport from './onGetClientStatusReport';
import onGetClientTreatmentPlansReport from './onGetClientTreatmentPlansReport';
import onGetEffectiveDates from './onGetEffectiveDates';
import onGetMedicationsReportClient from './onGetMedicationsReportClient';
import onGetMedicationsReport from './onGetMedicationsReport';
import onGetMedicationsReportTeam from './onGetMedicationsReportTeam';
import onGetClientLengthOfStayOverviewReport from './onGetClientLengthOfStayOverviewReport';
import onGetClientLengthOfStayDetailsReport from './onGetClientLengthOfStayDetailsReport';
import onGetLocationGroupOverviewReport from './onGetLocationGroupOverviewReport';
import onGetToxicologyOverviewReport from './onGetToxicologyOverviewReport';
import onGetClientLastContactOverviewReport from './onGetClientLastContactOverviewReport';
import onGetTeamMemberDashboardReport from './onGetTeamMemberDashboardReport';
import onGetVisitStatusOverviewReport from './onGetVisitStatusOverviewReport';
import onGetUtilizationOverviewReport from './onGetUtilizationOverviewReport';
import onGetClickOverviewReport from './onGetClickOverviewReport';
import onGetLocationSharingOverviewReport from './onGetLocationSharingOverviewReport';

export default {
  onGetRecertificationReports,
  onGetDashboardReport,
  onGetVisitsByTeamReport,
  onGetVisitsByClientReport,
  onGetVisitsListByTeamReport,
  onGetVisitsListByClientReport,
  onGetFavoriteReports,
  onAddFavoriteReport,
  onRemoveFavoriteReport,
  onGetClientAllocationVisitsReport,
  onGetDemographicsAvailableColumns,
  onGetDemographicsReport,
  onGetDemographicsReportByTeam,
  onGetTreatmentPlansReport,
  onGetClientStatusReport,
  onGetClientTreatmentPlansReport,
  onGetEffectiveDates,
  onGetMedicationsReportClient,
  onGetMedicationsReport,
  onGetMedicationsReportTeam,
  onGetClientLengthOfStayOverviewReport,
  onGetClientLengthOfStayDetailsReport,
  onGetLocationGroupOverviewReport,
  onGetToxicologyOverviewReport,
  onGetClientLastContactOverviewReport,
  onGetTeamMemberDashboardReport,
  onGetVisitStatusOverviewReport,
  onGetUtilizationOverviewReport,
  onGetClickOverviewReport,
  onGetLocationSharingOverviewReport,
};
