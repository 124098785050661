import { useCallback, useEffect, useState } from 'react';
import api from '~/services/api';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { useStoreActions } from '~/store/hooks';
import { IOption } from '~/types';
import { extractErrorMessage } from '~/utils/error/error';
import formatActTeamMemberOptions from '~/utils/formatActTeamMemberOptions';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { IRequestInfo } from '../types';

interface IUseMedDeliveris {
  loading: boolean;
  members: IOption[];
  reasons: IOption[];
}

const useMedDeliveris = (requestInfo: IRequestInfo, excludeDeleted?: boolean): IUseMedDeliveris => {
  const [members, setMembers] = useState<IOption[]>([]);
  const [reasons, setReasons] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(true);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const onMount = useCallback(async () => {
    try {
      const [teamMembers, nonDeliveryReasons] = await Promise.all([
        api.actTeamMember
          .getActTeamMemberList(requestInfo.clinicId, requestInfo.teamId)
          .then(r => formatActTeamMemberOptions(r.data, excludeDeleted)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.MedicationNonDeliveryReason)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setMembers(teamMembers);
      setReasons(nonDeliveryReasons);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [excludeDeleted, requestInfo.clinicId, requestInfo.teamId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return { loading, members, reasons };
};

export default useMedDeliveris;
