import { IResponse } from '~/services/api/types';
import { privateAxios } from '../requestsCollection';
import { ISendUtilizationDataRequestPayload } from '~/store/utilization/types';

export const sendUtilizationData = ({
  clinicId,
  data,
}: ISendUtilizationDataRequestPayload): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/usage`,
    data,
  });

export default {};
