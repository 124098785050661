import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { isToday } from 'date-fns';
import delay from 'lodash/delay';
import uniq from 'lodash/uniq';
import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import MenuList from '~/ui/components/inputs/SelectWithoutAnimation/components/MenuList';
import ClientMultiSelect from '~/ui/pages/Clients/reusable/ClientMultiSelect';
import MultiValue from '../components/MultiValue';
import Option from '../components/Option';
import AccordionMembers from '../components/AccordionMembers';
import useEditClientAllocation from '../hooks/useEditClientAllocation';
import extractArchivedClients from '../helpers/extractArchivedClients';
import { getAllocationIdByUserId, getClientsFormFieldName } from '../helpers/getters';

import extractLocationGroupClientsIds from '../helpers/extractLocationGroupClientsIds';
import useClientOptions from '~/store/client/hooks/useClientOptions';
import {
  formatLocationGroupOptions,
  formValuesMapper,
  ITeamMemberMapped,
  prepareDefaultValues,
} from '~/utils/clientAllocation';
import { getLocalDateNoFormat } from '~/utils/date/date';
import { extractErrorMessage } from '~/utils/error/error';
import extractFullName from '~/utils/text/extractFullName';
import filterNumberArray from '../helpers/filterNumberArray';
import hasLocationGroup from '../helpers/hasLocationGroup';

import {
  IAllocationClient,
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';
import { IClientOption } from '~/store/client/types';
import { IUserRole, IOption } from '~/types';
import { NETWORK, VIEW_CLIENT_ALLOCATION, VIEW_MY_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import { AllocationMode } from '../constants/allocationMode';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './EditClientAllocation.module.scss';

interface IProps {
  teamMembers: ITeamMemberMapped[];
  clients: IOption[];
  clientAllocation: IClientAllocationDetailed[];
  actTeamId: string;
  assignedClients?: number[];
  clinicId: string;
  locationGroups: IAllocationClientsByGroup[];
}

const Form = ({
  clientAllocation,
  teamMembers,
  clients,
  actTeamId,
  assignedClients = [],
  clinicId,
  locationGroups,
}: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  // * details used to transfer data from form to modal
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();
  const isLocationGroupAllocation = hasLocationGroup(clientAllocation);

  const defValues = {
    date: clientAllocation[0].date,
    allocationMode: isLocationGroupAllocation
      ? AllocationMode.BY_LOCATION_GROUP
      : AllocationMode.BY_CLIENT,
    ...prepareDefaultValues(clientAllocation),
  };

  const form = useForm({
    defaultValues: defValues,
  });
  const {
    control,
    formState: { errors, isDirty },
    watch,
    setValue,
    handleSubmit,
  } = form;
  const { date: formDate, allocationMode, ...formValues } = watch();

  const userIdsWithClients = useMemo(
    () => clientAllocation?.filter(item => !item.isArchived)?.map(item => item.user.id),
    [clientAllocation],
  );

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { isActTeam, isActTeamMember, isGlobalUser } = useRole();

  const newAssignedClients = formValues as {
    [key: string]: number[];
  };

  const unique = new Set([...formValuesMapper(formValues), ...assignedClients]); // un-mark clients who has already been assigned (for team member role)

  const { availableActTeams } = useStoreState(state => state.actTeam);
  const availableTeamIds = useMemo(() => availableActTeams?.map(t => t.id), [availableActTeams]);
  const { loading, loadMore, setClientOptionsTeamIds, setCurrentClientOption } = useClientOptions();
  const { current } = useStoreState(store => store.client.clientOptions);

  useEffect(() => {
    if (isGlobalUser) {
      setClientOptionsTeamIds(availableTeamIds);
    }
  }, [availableTeamIds, isGlobalUser, setClientOptionsTeamIds]);

  const selectedClientOptions: IClientOption[] = useMemo(
    () =>
      clientAllocation?.flatMap(item =>
        (item.user.clients || []).map(client => ({
          id: client.id,
          name: extractFullName(client),
          photo: client.photo,
          team: item.team,
        })),
      ),
    [clientAllocation],
  );

  useEffect(() => {
    if (isGlobalUser) {
      delay(() => setCurrentClientOption(selectedClientOptions), 0);
    }
  }, [selectedClientOptions, isGlobalUser, setCurrentClientOption]);

  const deletedMembers = useMemo(
    () =>
      teamMembers.filter(
        teamMember => teamMember.isDeleted && userIdsWithClients.includes(teamMember.id),
      ),
    [teamMembers, userIdsWithClients],
  );

  const activeMembers = useMemo(
    () => teamMembers.filter(teamMember => !teamMember.isDeleted),
    [teamMembers],
  );

  const additionalStyleHandler = () => ({
    option: (provided: any, { data }: any) => {
      const notSelected = !unique?.has(data?.value);

      return {
        ...provided,
        backgroundColor: notSelected ? '#FFF1EE' : 'inherit',
        fontWeight: '300',
        color: variables.colorBlack,
      };
    },
  });

  const handleRemoveGroup = (id: number, field: string) => {
    const groupField = `groups${field}`;
    const clientField = `clients${field}`;
    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    const newGroups = filterNumberArray(newAssignedClients[groupField], id);
    const newClients =
      newAssignedClients[clientField]?.filter(item => !(groupClientsIds || []).includes(item)) ||
      [];

    setValue(clientField as any, newClients);
    setValue(groupField as any, newGroups);
  };

  const handleRemoveClient = (id: number, field: string) => {
    const newClients = filterNumberArray(newAssignedClients[field], id);
    setValue(field as any, newClients);
  };

  const handleRemoveClientInLocationGroup = (id: number, field: string, groupName?: string) => {
    const clientField = `clients${field}`;
    const newClients = filterNumberArray(newAssignedClients[clientField], id);

    let locationGroupId;
    if (!newClients.length && groupName) {
      locationGroupId = locationGroups.find(group => group.locationGroup.name === groupName)
        ?.locationGroup.id;
    } else {
      locationGroupId = locationGroups.find(
        group => !group.clients.some(client => newClients.includes(client.id)),
      )?.locationGroup.id;
    }

    if (locationGroupId) {
      handleRemoveGroup(locationGroupId, field);
    }

    setValue(clientField as any, newClients);
  };
  const handleSelectGroup = (id: number, field: string) => {
    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    const newValue = Array.from(
      new Set([...groupClientsIds, ...(newAssignedClients[field] || [])]),
    );

    setValue(field as any, newValue);
  };

  const onSubmitHandler = useEditClientAllocation({
    clinicId,
    isGlobalUser,
    isActTeamMember,
    clientAllocation,
    actTeamId,
    current: selectedClientOptions,
    locationGroups,
  });

  const onSubmit = async (values: Record<string, any>) => {
    try {
      const archivedClients = extractArchivedClients(clientAllocation);

      if (archivedClients.length) {
        const archivedClientsNames = uniq(
          archivedClients.map((client: IAllocationClient) => extractFullName(client)),
        ).join(', ');
        const isMultipleArchived = archivedClients.length > 1;
        showError(
          `The archived client${isMultipleArchived ? 's' : ''} ${archivedClientsNames} ha${
            isMultipleArchived ? 've' : 's'
          } been removed from the created allocation`,
        );
      }

      delay(
        async () => {
          setIsAdding(true);

          await onSubmitHandler(values);

          showNotify({ message: 'Client allocation successfully updated' });
        },
        archivedClients.length ? 3000 : 0,
      );
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsAdding(false);
      setDetails(null);
    }
  };

  const openConfirmationModal = ({ date, ...rest }: any) => {
    if (getLocalDateNoFormat(date) < new Date() && !isToday(getLocalDateNoFormat(date))) {
      setDetails(rest);
      return;
    }
    onSubmit(rest);
  };

  const handleSelectClients = (options: IClientOption[], field: string) => {
    const newClients = options.map(o => o.id);
    setValue(field as any, newClients);
  };

  const handleCancel = () => {
    if (isGlobalUser || isActTeam) {
      navigate(
        (isGlobalUser ? VIEW_MY_CLIENT_ALLOCATION : VIEW_CLIENT_ALLOCATION).replace(
          ':clientAllocationDate',
          clientAllocation[0].date.split('T')[0],
        ),
      );
    } else navigate(NETWORK);
  };

  const locationGroupClients = formatLocationGroupOptions(locationGroups);

  // List of all clients
  const clientsToRender = useMemo(() => {
    if (allocationMode === AllocationMode.BY_CLIENT) {
      if (isGlobalUser) {
        return current.map(client => ({
          name: client.name,
          id: Number(client.id),
          photo: client.photo,
        }));
      }

      return clients.map(client => ({
        name: client.label,
        id: Number(client.value),
        photo: client.photo,
      }));
    }

    return locationGroupClients;
  }, [isGlobalUser, clients, current, locationGroupClients, allocationMode]);

  const renderMembers = (members: ITeamMemberMapped[]) => {
    const renderSelect = (teamMember: ITeamMemberMapped, formFieldName: string) => {
      const renderClientSelect = () => {
        if (isGlobalUser) {
          return (
            <ClientMultiSelect
              loading={loading}
              loadMore={loadMore}
              label="Assign Clients"
              customComponents={{ MenuList }}
              additionalStyleHandler={additionalStyleHandler}
              onSelect={v => handleSelectClients(v, `clients${formFieldName}`)}
            />
          );
        }

        return (
          <SelectComponent
            closeMenuOnSelect={false}
            showCustomComponents
            hideSelectedOptions={false}
            additionalStyleHandler={() => additionalStyleHandler()}
            isMulti
            label="Assign Clients"
            options={clients}
            disableMenu={teamMember.isDeleted}
            name={`clients${formFieldName}`}
            control={control}
            errors={errors}
          />
        );
      };

      return (
        <Fragment key={teamMember.id}>
          <Grid item sm={11}>
            {allocationMode === AllocationMode.BY_CLIENT ? (
              renderClientSelect()
            ) : (
              <SelectComponent
                closeMenuOnSelect={false}
                showCustomComponents
                hideSelectedOptions={false}
                additionalStyleHandler={() => additionalStyleHandler()}
                isMulti
                label="Location Group"
                options={locationGroupClients}
                disableMenu={teamMember.isDeleted}
                name={`groups${formFieldName}`}
                control={control}
                errors={errors}
                customComponents={{
                  MenuList: props => <MenuList {...props} label="Location Groups" />,
                  MultiValue: props => (
                    <MultiValue
                      {...props}
                      clientIds={newAssignedClients[`clients${formFieldName}`]}
                      locationGroups={locationGroups}
                      onDelete={id => handleRemoveClient(id, `clients${formFieldName}`)}
                      onDeleteGroup={id => handleRemoveGroup(id, formFieldName)}
                    />
                  ),
                  Option: props => (
                    <Option
                      {...props}
                      selectGroup={id => handleSelectGroup(id, `clients${formFieldName}`)}
                    />
                  ),
                }}
              />
            )}
          </Grid>
        </Fragment>
      );
    };

    return (
      <>
        {members.map(teamMember => {
          const allocationId = getAllocationIdByUserId(teamMember.id, clientAllocation);
          const formFieldName = getClientsFormFieldName(teamMember.id, allocationId);

          const hasGlobalUserRole = !!teamMember.roles
            ?.filter(r => r.clinic.id === Number(clinicId))
            .find(r => r.name === IUserRole.GlobalUser);

          return (
            <AccordionMembers
              clientsList={clientsToRender as IClientOption[]}
              key={teamMember.id}
              member={teamMember}
              renderSelect={() => renderSelect(teamMember, formFieldName)}
              hasGlobalUserRole={hasGlobalUserRole}
              clientAllocationDate={clientAllocation[0].date}
              errorDates={[]}
              showDifferentTeamErrorMessage={false}
              allocationMode={allocationMode}
              locationGroups={locationGroups}
              handleRemoveClient={handleRemoveClient}
              handleRemoveClientInLocationGroup={handleRemoveClientInLocationGroup}
              formFieldName={formFieldName}
            />
          );
        })}
      </>
    );
  };

  const leavePageDescription =
    'Are you sure you want to cancel Edit Client Allocation"? All the changes will be discarded.';

  const editPastAllocationDescription =
    'Caution. You are modifying a past date. Are you sure you want to proceed?';

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(openConfirmationModal)}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={4}>
              <Grid item sm={12}>
                <Typography variant="subtitle1" className={styles.assignedClientsTitle}>
                  {allocationMode === AllocationMode.BY_CLIENT
                    ? 'Assigned Clients per Team Member'
                    : 'Location Groups per Team Member'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={styles.formWrapper}>
              {renderMembers(activeMembers)}

              {!!deletedMembers.length && (
                <>
                  <Grid item sm={12}>
                    <h5 className={styles.subTitle}>Deleted/Deactivated Team Members</h5>
                  </Grid>
                  {renderMembers(deletedMembers)}
                </>
              )}
            </Grid>
          </Box>
        </Paper>

        <div className={styles.buttons}>
          <div className={styles.buttonWrapper}>
            <Button color="primary" variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <Button color="primary" variant="contained" type="submit">
            Save Changes
          </Button>
        </div>

        <NavigationConfirmModal when={isDirty && !isAdding} description={leavePageDescription} />
        {/* this modal triggers only when allocation date is in the past */}
        {details && (
          <ConfirmModal
            onConfirm={() => {
              onSubmit(details);
            }}
            onClose={() => setDetails(null)}
            description={editPastAllocationDescription}
            confirmText="Proceed"
          />
        )}
      </form>
    </FormProvider>
  );
};

export default Form;
