import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IUtilizationActions, IThunkSendUtilizationData, IUtilizationStore } from '../types';

const onSendUtilizationData: IThunkSendUtilizationData = thunk(
  async ({ setUtilizationUsers }: Actions<IUtilizationActions>, payload, { getState }) => {
    const { userId, teamId, data } = payload;
    const { date } = data;
    await api.utilization.sendUtilizationData(payload);
    const utilizationStore = getState() as IUtilizationStore;

    const userKey = teamId ? `${userId}_${teamId}` : `${userId}`;

    const { users } = utilizationStore;

    if (users[userKey]?.[date]) {
      delete users[userKey][date];
    }
    setUtilizationUsers(users);
  },
);

export default onSendUtilizationData;
