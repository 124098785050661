import { action } from 'easy-peasy';
import { setLocationSharingReportFilter } from '~/services/localStorage/filters';

import { IActionSetLocationSharingReportFilters } from '../types';

const setLocationSharingReportFilters: IActionSetLocationSharingReportFilters = action(
  (state, payload) => {
    const updatedFilters = { ...state.locationSharing.filters, ...payload };
    // save filters to storage
    setLocationSharingReportFilter(updatedFilters);

    state.locationSharing.filters = updatedFilters;
  },
);

export default setLocationSharingReportFilters;
