enum UtilizationModule {
  network = 'Network',
  coordinate = 'Coordinate',
  visits = 'Visits',
  medicationLogistics = 'MedicationLogistics',
  clientAllocation = 'ClientAllocation',
  ai = 'Ai',
  resources = 'Resources',
  clients = 'Clients',
  userProfile = 'UserProfile',
  reports = 'Reports',
}

export default UtilizationModule;
