import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetUtilizationOverviewReport } from '../types';

const onGetUtilizationOverviewReport: IThunkGetUtilizationOverviewReport = thunk(
  async (
    { setUtilizationOverviewReport }: Actions<IReportsActions>,
    { requestInfo, requestPayload },
  ) => {
    const utilizationReport = await api.reports
      .getUtilizationOverviewReport(requestInfo, requestPayload)
      .then(r => r.data);

    setUtilizationOverviewReport({
      items: utilizationReport.items,
      pagination: {
        total: utilizationReport.total,
        pageNumber: requestPayload.pagination.pageNumber,
        pageSize: requestPayload.pagination.pageSize,
      },
    });
  },
);

export default onGetUtilizationOverviewReport;
