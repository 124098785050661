import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { ReactElement, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useStoreActions, useStoreState } from '~/store/hooks';
import buildDate from '~/utils/date/buildDate';
import { extractErrorMessage } from '~/utils/error/error';
import getFromDate from '~/ui/pages/MedicationLogistics/helpers/getFromDate';
import getToDate from '~/ui/pages/MedicationLogistics/helpers/getToDate';
import { medicationDeliveryValidationSchema } from './validates';
import useMedDeliveris from './hooks/useMedDeliveris';
import sortByLabel from '~/utils/sorting/sortByLabel';

import DateRangePicker from '~/ui/components/inputs/DateRangePicker';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import MultiValueNoChipText from '~/ui/components/inputs/SelectWithoutAnimation/components/MultiValueNoChipText';
import TimePicker from '~/ui/components/inputs/TimePicker';
import MedicationChangeInfo from '~/ui/pages/MedicationLogistics/components/MedicationChangeInfo';
import MedicationDeliveryInfo from '~/ui/pages/MedicationLogistics/components/MedicationDeliveryInfo';

import { IMedication } from '~/services/api/client/types';
import { IOption } from '~/types';
import { IMedicationDeliveryInitialValues, IRequestInfo } from './types';
import { IDetails } from '../types';
import styles from './Styles.module.scss';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';

interface IProps {
  defaultValues: IMedicationDeliveryInitialValues;
  requestInfo: IRequestInfo;
  details: IDetails | null;
  setModalTitle: (v: string | null) => void;
  setDetails: (v: IDetails | null) => void;
}

const AddMedicationDelivery = ({
  defaultValues,
  details,
  requestInfo,
  setDetails,
  setModalTitle,
}: IProps): ReactElement => {
  const {
    control,
    formState: { errors },
    reset,
    register,
    handleSubmit,
    unregister,
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: medicationDeliveryValidationSchema,
  });

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const {
    onGetMedicationDeliveries,
    onUpdateMedicationDelivery,
    onAddMedicationDelivery,
    setMedicationDelivery,
  } = useStoreActions(actions => actions.medicationLogistics);

  const { current } = useStoreState(state => state.client);
  const currentMedicationDelivery = useStoreState(state => state.medicationLogistics.current);
  const query = useQuery();
  const selectedMonthQuery = query.get('selectedMonth');
  const refetchDate = selectedMonthQuery ? new Date(selectedMonthQuery) : null;

  useEffect(
    () => () => {
      setDetails(null);
      setModalTitle(null);
      setMedicationDelivery(null);
    },
    [setDetails, setMedicationDelivery, setModalTitle],
  );

  const medications = useMemo(
    () =>
      current.medications
        .reduce((acc: IOption[], obj: IMedication) => {
          if (!obj.isArchived && !obj.injectable) {
            let label = obj.medication.name;
            if (obj.dose) {
              label = `${label} (${obj.dose})`;
            }
            if (obj.outsideFacility) {
              label = `${label} <chip>O.F.</chip>`;
            }

            acc.push({
              label,
              value: obj.id,
            });
          }
          return acc;
        }, [])
        .sort(sortByLabel),
    [current.medications],
  );

  const { notDelivered, groupDelivery } = watch();

  const { members, reasons, loading } = useMedDeliveris(requestInfo, !defaultValues?.id);

  const refetchDeliveries = (date: Date) => {
    onGetMedicationDeliveries({
      clinicId: String(requestInfo.clinicId),
      clientId: String(current.id),
      teamId: String(requestInfo.teamId),
      from: getFromDate(date) as string,
      to: getToDate(date) as string,
    });
  };

  const onSubmit = async (values: IMedicationDeliveryInitialValues, evt: any) => {
    const {
      time,
      date: medicationDeliveryDate,
      archiveMedication,
      hasAlerts,
      lastInjection,
      nextDueDate,
      notificationOn,
      notificationTime,
      teamMembersToNotify,
      statusChangeLog,
      dateRange,
      ...rest
    } = values;

    const isGroupDelivery = values.groupDelivery && !values.notDelivered;
    const groupStartDate = dateRange?.[0] ? new Date(dateRange[0]).toISOString() : undefined;

    const date = buildDate(
      time,
      isGroupDelivery && groupStartDate ? groupStartDate : medicationDeliveryDate,
    );

    const groupEndDate = dateRange?.[1]
      ? buildDate(time, new Date(dateRange[1]).toISOString())
      : undefined;

    const requestPayload = {
      ...rest,
      date,
      groupDelivery: isGroupDelivery,
      groupEndDate,
      clientId: current.id,
    };

    const { name } = evt.nativeEvent.submitter;
    try {
      if (details && details.id) {
        const payload = {
          requestInfo,
          requestPayload: { ...requestPayload, medicationDeliveryId: String(details.id) },
        };
        await onUpdateMedicationDelivery(payload);
        setDetails(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload,
        };
        await onAddMedicationDelivery(payload);
        reset({ notDelivered: false, id: null, note: '', medicationIds: [], teamMemberId: null });
      }

      refetchDeliveries(isGroupDelivery ? refetchDate : new Date(date));
      setModalTitle(name);
      setMedicationDelivery(null);

      const type = details?.id ? 'updated' : 'added';

      showNotify({ message: `Medication delivery successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    if (!notDelivered) {
      unregister(['nonDeliveryReasonId']);
    }
  }, [notDelivered, unregister]);

  const filteredMembers = useMemo(
    () =>
      members.filter(
        member => !member.isDisabled || defaultValues?.teamMemberId === (member.value as number),
      ),
    [members, defaultValues],
  );

  if (loading) return <Loader />;

  const fullName = `${current.firstName} ${current.lastName}`;

  const showSecondSubmit = !details?.id;

  const setAll = (all?: boolean) => {
    register('medicationIds');
    if (all)
      setValue(
        'medicationIds',
        medications.map(m => Number(m.value)),
      );
    else {
      setValue('medicationIds', []);
    }
  };

  const additionalStyleHandler = () => ({
    menuList: (provided: any) => ({ ...provided, height: '100%' }),
    menu: (provided: any) => ({ ...provided, minHeight: '50px', maxHeight: '200px' }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MedicationDeliveryInfo
            name={fullName}
            medication={currentMedicationDelivery}
            fullInfo={!!details?.id}
          />
        </Grid>
        {!currentMedicationDelivery && (
          <Grid item sm={12}>
            <SelectComponent
              isMulti
              closeMenuOnSelect={false}
              showCustomComponents
              showSelectAll
              hideSelectedOptions={false}
              name="medicationIds"
              options={medications}
              additionalStyleHandler={() => additionalStyleHandler()}
              control={control}
              errors={errors}
              label="Select Medication"
              isDisabled={!!defaultValues?.id}
              setAll={setAll}
              customComponents={{
                MultiValue: MultiValueNoChipText,
              }}
            />
          </Grid>
        )}

        {!defaultValues?.groupDelivery && (
          <Grid item sm={6}>
            <Checkbox
              size="small"
              name="notDelivered"
              control={control}
              errors={errors}
              label="Medication not delivered"
            />
          </Grid>
        )}
        <Grid item sm={6}>
          <Checkbox
            size="small"
            name="outsideFacility"
            control={control}
            errors={errors}
            label="Outside Facility"
          />
        </Grid>
        {defaultValues.statusChangeLog && (
          <Grid item sm={12}>
            <MedicationChangeInfo
              delivered={defaultValues.statusChangeLog.delivered}
              when={defaultValues.statusChangeLog.date}
              changedBy={defaultValues.statusChangeLog.user.name}
            />
          </Grid>
        )}
        {notDelivered && (
          <Grid item sm={12}>
            <SelectComponent
              options={reasons}
              name="nonDeliveryReasonId"
              control={control}
              errors={errors}
              label="Select Reason"
              hideSelectedOptions={false}
            />
          </Grid>
        )}
        <Grid item sm={12}>
          <SelectComponent
            options={filteredMembers}
            name="teamMemberId"
            control={control}
            errors={errors}
            label="Select Responsible Team Member"
            hideSelectedOptions={false}
          />
        </Grid>
        <Grid item sm={12}>
          <Input name="note" register={register} label="Note" multiline errors={errors} />
        </Grid>
        <Grid item sm={6}>
          {groupDelivery && !notDelivered ? (
            <DateRangePicker
              label="Select Date Range"
              control={control}
              name="dateRange"
              errors={errors}
            />
          ) : (
            <DatePicker
              name="date"
              openTo="date"
              control={control}
              errors={errors}
              label="Delivery Date"
              maxDate={new Date().toString()}
            />
          )}
        </Grid>

        <Grid item sm={6}>
          <TimePicker name="time" control={control} label="Delivery Time" errors={errors} />
        </Grid>
        {!notDelivered && (defaultValues?.groupDelivery || !defaultValues?.id) && (
          <Grid item sm={12}>
            <Checkbox
              size="small"
              name="groupDelivery"
              control={control}
              errors={errors}
              label="Deliver for a date range"
              disabled={!!defaultValues?.id}
            />
          </Grid>
        )}
      </Grid>
      <div className={classNames(styles.buttonsWrapper, styles.marginVertical)}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setMedicationDelivery(null);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={styles.margin}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddMedicationDelivery;
