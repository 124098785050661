import { IVisit, IVisitForm } from '~/services/api/visits/types';
import { destructHours, getLocalDateNoFormat } from '~/utils/date/date';
import { visitTypes } from '../constants/filterOptions';
import splitSeconds from '../../../../utils/date/splitSeconds';

const formatVisitToForm = (visit: IVisit): IVisitForm => ({
  date: new Date(getLocalDateNoFormat(visit?.date).setHours(12)).toISOString(),
  type: Object.keys(visitTypes)
    .filter(x => Number.isNaN(Number(x)))
    .indexOf(visit?.type),
  visitedPeople: [
    ...visit?.visitedPeople?.map(people => people.id),
    visit?.isClientVisited ? [visit?.client?.id] : [],
    visit?.isHospitalStaffVisited ? [0] : [],
  ].flat(1),
  visitors: visit?.visitedBy?.map(visitor => visitor.id),
  note: visit?.note,
  startTime: visit?.startTime ? destructHours(visit?.startTime).toISOString() : null,
  duration: splitSeconds(visit?.durationSeconds, true),
  city: visit?.address?.city,
  zipCode: visit?.address?.zipCode,
  stateId: visit?.address?.state?.id,
  clientNoShow: visit?.clientNoShow,
  noShowReason: visit?.noShowReason?.id,
  line1: visit?.address?.line1,
  line2: visit?.address?.line2,
  paperworkStatus: visit?.paperworkStatus,
  billable: visit?.billable,
  interventionIds: visit?.interventions?.map(intervention => intervention.id) || [],
  toxicology: visit?.toxicologyResults?.toxicology || {
    status: null,
    date: null,
    result: null,
  },
  sendToEmr: !!visit?.sendToEmr,
  bloodDrawn: visit?.toxicologyResults?.bloodDrawn
    ? {
        ...(visit?.toxicologyResults?.bloodDrawn || {}),
        drawDate: visit?.toxicologyResults?.bloodDrawn.date,
      }
    : {
        status: null,
        drawDate: null,
      },
});

export default formatVisitToForm;
