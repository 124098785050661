import isToday from 'date-fns/isToday';
import throttle from 'lodash/throttle';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import UtilizationModule from '~/store/utilization/constants/utilizationModule';
import { extractErrorMessage } from '~/utils/error/error';

const DEBOUNCE_DELAY = 1000;
const INACTIVITY_TIMEOUT = 1000 * 60 * 3; // 3 mins

const useUtilization = (): {
  timeout: number;
  resumeUtilization: () => void;
  stopUtilization: () => void;
} => {
  const location = useLocation();

  const { setUtilizationModule, onSendUtilizationData } = useStoreActions(
    actions => actions.utilization,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const { current } = useStoreState(state => state.user);
  const { users } = useStoreState(state => state.utilization);

  const { isActTeam } = useRole();
  const teamId = isActTeam ? current?.teamId : null;
  const userId = current?.id;
  const clinicId = current?.clinic?.id;

  const resumeUtilization = useCallback(
    () => {
      throttle(() => {
        if (userId && location?.pathname) {
          setUtilizationModule({ route: location.pathname, teamId, userId });
        }
      }, DEBOUNCE_DELAY)();
    }, // Adjust throttle time (e.g., 5000ms = 5 seconds)
    [location?.pathname, teamId, userId, setUtilizationModule],
  );

  const stopUtilization = useCallback(() => {
    throttle(() => {
      setUtilizationModule(null);
    }, DEBOUNCE_DELAY)();
  }, [setUtilizationModule]);

  useEffect(() => {
    resumeUtilization();
  }, [resumeUtilization]);

  const syncUtilizationData = useCallback(() => {
    const userKey = teamId ? `${userId}_${teamId}` : `${userId}`;
    const utilizationData = users[userKey];
    if (utilizationData && clinicId) {
      Object.keys(utilizationData).forEach(async date => {
        if (isToday(new Date(date))) {
          // BE overwrite utilization data for the same day, so this condition allows to send data only once per day
          return;
        }
        const modules = Object.entries(utilizationData[date]).map(([key, value]) => ({
          teamId,
          module: key as UtilizationModule,
          usageSeconds: (value as { timeSpent: number }).timeSpent,
        }));

        try {
          const data = { date, modules };
          await onSendUtilizationData({ clinicId, teamId, userId, data });
        } catch (e) {
          showError(extractErrorMessage(e));
        }
      });
    }
  }, [clinicId, onSendUtilizationData, showError, teamId, userId, users]);

  useEffect(() => {
    syncUtilizationData();
  }, [syncUtilizationData]);

  return { timeout: INACTIVITY_TIMEOUT, resumeUtilization, stopUtilization };
};

export default useUtilization;
