import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import delay from 'lodash/delay';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import MenuList from '~/ui/components/inputs/SelectWithoutAnimation/components/MenuList';
import ClientMultiSelect from '~/ui/pages/Clients/reusable/ClientMultiSelect';
import useMemoCompare from '~/hooks/useMemoCompare';
import MultiValue from './components/MultiValue';
import Option from './components/Option';
import validate, { IFormValues } from './validate';

import { IDatesValidation } from '~/services/api/clientAllocation';
import useClientOptions from '~/store/client/hooks/useClientOptions';
import {
  formatLocationGroupOptions,
  formValuesMapper,
  ITeamMemberMapped,
  transformFormToPayload,
} from '~/utils/clientAllocation';
import { normalizeDateString } from '~/utils/date/date';
import { extractErrorMessage } from '~/utils/error/error';
import extractFullName from '~/utils/text/extractFullName';
import extractArchivedClients from './helpers/extractArchivedClients';
import extractLocationGroupClientsIds from './helpers/extractLocationGroupClientsIds';
import filterNumberArray from './helpers/filterNumberArray';
import getIsValidAllocationDates from './helpers/getIsValidAllocationDates';

import {
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';
import { IClientOption } from '~/store/client/types';
import { IUserRole } from '~/types';
import { IOption } from '~/ui/components/inputs/Select/types';
import { NETWORK, VIEW_CLIENT_ALLOCATION, VIEW_MY_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import { AllocationMode } from './constants/allocationMode';

import { ReactComponent as DangerIcon } from '~/ui/assets/images/danger.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './ClientAllocationForm.module.scss';
import AccordionMembers from './components/AccordionMembers';
import AddAllocationFormToolbar from './components/AccordionMembers/AddAllocationFormToolbar';
import { Typography } from '@material-ui/core';
import useAddClientAllocationRequest from './hooks/useAddClientAllocation';
import { getAllocationIdByUserId, getClientsFormFieldName } from './helpers/getters';

interface IProps {
  teamMembers: ITeamMemberMapped[];
  clients: IOption[];
  locationGroups: IAllocationClientsByGroup[];
  actTeamId?: string;
  defaultValues?: any;
  clientAllocationDate?: string;
  clientAllocations?: IClientAllocationDetailed[];
  clinicId: string;
  clientsList?: IClientOption[];
}

const defValues = {
  dateRange: '',
  includeWeekends: false,
};

const initialDatesValidation: IDatesValidation = {
  isValid: true,
  users: [],
};

const Form = ({
  actTeamId,
  teamMembers,
  clients,
  locationGroups,
  clinicId,
  clientAllocationDate,
  clientAllocations,
  clientsList,
  defaultValues = defValues,
}: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  const [datesValidation, setDatesValidation] = useState<IDatesValidation>(initialDatesValidation);

  const navigate = useNavigate();
  const { isActTeamMember, isGlobalUser } = useRole();
  const { loading, loadMore, setClientOptionsTeamIds, setCurrentClientOption } = useClientOptions();

  const isDuplicatingLocationGroups = Object.keys(defaultValues).some(key =>
    key.includes('groups'),
  );

  const addClientAllocationRequest = useAddClientAllocationRequest({
    isGlobalUser,
    isActTeamMember,
  });

  const initialAllocationMode = isDuplicatingLocationGroups
    ? AllocationMode.BY_LOCATION_GROUP
    : AllocationMode.BY_CLIENT;

  const form = useForm<IFormValues>({
    defaultValues: {
      ...defaultValues,
      allocationMode: initialAllocationMode,
    },
    resolver: validate,
  });
  const {
    control,
    watch,
    clearErrors,
    setValue,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
  } = form;
  const { includeWeekends, dateRange: range, allocationMode, ...formValues } = watch();
  const { current } = useStoreState(store => store.client.clientOptions);
  // reset form on mode change
  useEffect(() => {
    if (!isDuplicatingLocationGroups) {
      reset({ ...defaultValues, allocationMode, includeWeekends, dateRange: range });
      setCurrentClientOption([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationMode, reset]);

  const [from, to] = range || [];

  const assignedClients = formValues as { [key: string]: number[] };

  const selectedMembersIds = useMemo(
    () =>
      Object.keys(assignedClients)
        .filter(key => assignedClients[key]?.length)
        .map(key => Number(key.split('_')[1])),
    [assignedClients],
  );

  const memoizedSelectedMembersIds = useMemoCompare(
    selectedMembersIds,
    (prev, next) => prev && isEqual(prev, next),
  );

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const deletedMembers = useMemo(
    () => teamMembers.filter(teamMember => teamMember.isDeleted),
    [teamMembers],
  );

  const activeMembers = useMemo(
    () => teamMembers.filter(teamMember => !teamMember.isDeleted),
    [teamMembers],
  );

  const handleRemoveGroup = (id: number, field: string) => {
    const groupField = `groups${field}`;
    const clientField = `clients${field}`;
    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    const newGroups = filterNumberArray(assignedClients[groupField], id);
    const newClients =
      assignedClients[clientField]?.filter(item => !(groupClientsIds || []).includes(item)) || [];

    setValue(clientField as any, newClients);
    setValue(groupField as any, newGroups);
  };

  const handleRemoveClient = (id: number, field: string) => {
    const newClients = filterNumberArray(assignedClients[field], id);
    if (isGlobalUser) {
      setCurrentClientOption(current.filter(client => client.id !== id));
    }
    setValue(field as any, newClients);
  };

  const handleRemoveClientInLocationGroup = (id: number, field: string, groupName?: string) => {
    const clientField = `clients${field}`;
    const newClients = filterNumberArray(assignedClients[clientField], id);

    let locationGroupId;
    if (!newClients.length && groupName) {
      locationGroupId = locationGroups.find(group => group.locationGroup.name === groupName)
        ?.locationGroup.id;
    } else {
      locationGroupId = locationGroups.find(
        group => !group.clients.some(client => newClients.includes(client.id)),
      )?.locationGroup.id;
    }

    if (locationGroupId) {
      handleRemoveGroup(locationGroupId, field);
    }

    setValue(clientField as any, newClients);
  };

  const handleSelectGroup = (id: number, field: string) => {
    const groupField = `groups${field}`;
    const clientField = `clients${field}`;

    const isSelectedGroup = assignedClients[groupField]?.includes(id);

    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    if (isSelectedGroup) {
      const newClients =
        assignedClients[clientField]?.filter(item => !(groupClientsIds || []).includes(item)) || [];

      setValue(clientField as any, newClients);
    } else {
      const newValue = Array.from(
        new Set([...groupClientsIds, ...(assignedClients[clientField] || [])]),
      );

      setValue(clientField as any, newValue);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      setIsAdding(true);
      const payload = transformFormToPayload(values);

      const archivedClients = extractArchivedClients(clientAllocations);
      if (archivedClients.length) {
        const archivedClientsNames = uniq(
          archivedClients.map(client => extractFullName(client)),
        ).join(', ');
        const isMultipleArchived = archivedClients.length > 1;
        showError(
          `The archived client${isMultipleArchived ? 's' : ''} ${archivedClientsNames} ha${
            isMultipleArchived ? 've' : 's'
          } been removed from the created allocation`,
        );
      }

      if (!payload.allocations.length) {
        delay(
          () => showError('Please provide at least one client'),
          archivedClients.length ? 3000 : 0,
        );
        return;
      }

      await addClientAllocationRequest({
        clinicId,
        teamId: actTeamId,
        payload,
      });

      showNotify({ message: 'Client allocation successfully added' });
      navigate(NETWORK);
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  const unique = new Set(formValuesMapper(formValues as { [key: string]: number[] }));

  const additionalStyleHandler = () => ({
    option: (provided: any, { data }: any) => {
      const notSelected = !unique?.has(data?.value);

      return {
        ...provided,
        backgroundColor: notSelected ? '#FFF1EE' : 'inherit',
        fontWeight: '300',
        color: variables.colorBlack,
      };
    },
  });

  const handleSelectClients = (options: IClientOption[], field: string) => {
    const newClients = options.map(o => o.id);
    setValue(field as any, newClients);
  };

  const handleCancel = () => {
    if (clientAllocationDate) {
      navigate(
        isGlobalUser
          ? VIEW_MY_CLIENT_ALLOCATION
          : VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
              ':clientAllocationDate',
              String(clientAllocationDate.split('T')[0]),
            ),
      );
    } else navigate(NETWORK);
  };

  // validate allocation dates for availability
  const validateClientAllocationRange = useCallback(async () => {
    try {
      if (!from || !to) {
        return;
      }
      const payload = {
        from: normalizeDateString(from),
        to: normalizeDateString(to, true),
        includeWeekends,
        userIds: memoizedSelectedMembersIds,
      };

      const validateOwnMethod = isGlobalUser
        ? api.clientAllocation.validateDateRangeGU
        : api.clientAllocation.validateOwnDateRange;

      const method =
        isActTeamMember || isGlobalUser
          ? validateOwnMethod
          : api.clientAllocation.validateDateRange;

      const validationResult = await method({ clinicId, teamId: actTeamId }, payload).then(
        r => r.data,
      );

      setDatesValidation(validationResult);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [
    actTeamId,
    clinicId,
    from,
    includeWeekends,
    isActTeamMember,
    isGlobalUser,
    memoizedSelectedMembersIds,
    showError,
    to,
  ]);

  const { availableActTeams } = useStoreState(state => state.actTeam);
  const availableTeamIds = useMemo(() => availableActTeams.map(t => t.id), [availableActTeams]);

  useEffect(() => {
    if (isGlobalUser) {
      setClientOptionsTeamIds(availableTeamIds); // set client select available teams from which to get clients
    }
  }, [availableTeamIds, isGlobalUser, setClientOptionsTeamIds]);

  const selectedClientOptions: IClientOption[] = useMemo(
    () =>
      clientAllocations?.flatMap(item =>
        item.user.clients.map(client => ({
          id: client.id,
          name: extractFullName(client),
          photo: client.photo,
          team: item.team,
        })),
      ),
    [clientAllocations],
  );

  useEffect(() => {
    if (isGlobalUser) {
      delay(() => setCurrentClientOption(selectedClientOptions), 0);
    }
  }, [selectedClientOptions, isGlobalUser, setCurrentClientOption]);

  const renderMembers = (members: ITeamMemberMapped[]) => {
    const renderSelect = (
      teamMember: ITeamMemberMapped,
      showDifferentTeamErrorMessage: boolean,
      formFieldName: string,
    ) => {
      const renderClientSelect = () => {
        if (isGlobalUser) {
          return (
            <ClientMultiSelect
              loading={loading}
              loadMore={loadMore}
              label="Select Clients to Assign"
              customComponents={{ MenuList }}
              additionalStyleHandler={additionalStyleHandler}
              onSelect={v => handleSelectClients(v, `clients${formFieldName}`)}
            />
          );
        }

        return (
          <SelectComponent
            closeMenuOnSelect={false}
            showCustomComponents
            hideSelectedOptions={false}
            additionalStyleHandler={() => additionalStyleHandler()}
            isMulti
            label="Select Clients to Assign"
            options={clients}
            disableMenu={teamMember.isDeleted}
            name={`clients${formFieldName}`}
            control={control}
            errors={errors}
          />
        );
      };

      return (
        <Fragment key={teamMember.id}>
          <div>
            {allocationMode === AllocationMode.BY_CLIENT ? (
              renderClientSelect()
            ) : (
              <SelectComponent
                closeMenuOnSelect={false}
                showCustomComponents
                hideSelectedOptions={false}
                additionalStyleHandler={() => additionalStyleHandler()}
                isMulti
                label="Select Location Group"
                options={formatLocationGroupOptions(locationGroups)}
                disableMenu={teamMember.isDeleted}
                name={`groups${formFieldName}`}
                control={control}
                errors={errors}
                customComponents={{
                  MenuList: props => <MenuList {...props} label="Location Groups" />,
                  MultiValue: props => (
                    <MultiValue
                      {...props}
                      clientIds={assignedClients[`clients${formFieldName}`]}
                      locationGroups={locationGroups}
                      onDelete={id => handleRemoveClient(id, `clients${formFieldName}`)}
                      onDeleteGroup={id => handleRemoveGroup(id, formFieldName)}
                    />
                  ),
                  Option: props => (
                    <Option {...props} selectGroup={id => handleSelectGroup(id, formFieldName)} />
                  ),
                }}
              />
            )}
          </div>
          {showDifferentTeamErrorMessage && (
            <Grid item sm={12} className={styles.errorMessage}>
              <span>
                Note: this user has assigned client allocations for this date on other teams
              </span>
            </Grid>
          )}
        </Fragment>
      );
    };

    return (
      <>
        {members.map(teamMember => {
          const errorDates = uniq(
            datesValidation?.users
              .find(user => user.userId === teamMember.id)
              ?.unavailableDates.map(item => item.split('T')[0]) || [],
          );

          const showDifferentTeamErrorMessage = !!datesValidation?.users.find(
            user => user.userId === teamMember.id && (user.isDifferentTeam || isGlobalUser),
          );

          const hasGlobalUserRole = !!teamMember.roles
            ?.filter(r => r.clinic.id === Number(clinicId))
            .find(r => r.name === IUserRole.GlobalUser);

          const allocationId = getAllocationIdByUserId(teamMember.id, clientAllocations);
          const formFieldName = getClientsFormFieldName(teamMember.id, allocationId);

          return (
            <AccordionMembers
              key={teamMember.id}
              member={teamMember}
              clientsList={!isGlobalUser ? clientsList : current}
              renderSelect={() =>
                renderSelect(teamMember, showDifferentTeamErrorMessage, formFieldName)
              }
              hasGlobalUserRole={hasGlobalUserRole}
              clientAllocationDate={clientAllocationDate}
              errorDates={errorDates}
              showDifferentTeamErrorMessage={showDifferentTeamErrorMessage}
              allocationMode={allocationMode}
              locationGroups={locationGroups}
              handleRemoveClient={handleRemoveClient}
              handleRemoveClientInLocationGroup={handleRemoveClientInLocationGroup}
              formFieldName={formFieldName}
            />
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (to && from && memoizedSelectedMembersIds?.length) {
      clearErrors();
      if (memoizedSelectedMembersIds?.length) {
        validateClientAllocationRange();
      }
    } else if (!memoizedSelectedMembersIds.length) {
      setDatesValidation(initialDatesValidation);
    }
  }, [to, from, memoizedSelectedMembersIds.length, validateClientAllocationRange, clearErrors]);

  const leavePageDescription =
    'Are you sure you want to cancel "Add Client Allocation"? All the changes will be discarded.';

  const isValidAllocationDates =
    datesValidation.isValid || getIsValidAllocationDates(datesValidation) || isGlobalUser;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box p={3}>
            <div className={styles.header}>
              <h4 className={styles.subTitle}>Client Allocation Details</h4>
            </div>

            <AddAllocationFormToolbar clientAllocationDate={clientAllocationDate} />

            {!datesValidation.isValid && (
              <Grid item sm={12} container direction="row" alignItems="center">
                <DangerIcon color={variables.colorOrange} />
                <h5 className={styles.validationErrors}>
                  {`${datesValidation.users.length} team member${
                    datesValidation.users.length > 1 ? 's' : ''
                  } already ${
                    datesValidation.users.length > 1 ? 'have' : 'has'
                  } allocations within this
                  period!`}
                </h5>
              </Grid>
            )}

            <Typography variant="subtitle1" className={styles.assignedClientsTitle}>
              {allocationMode === AllocationMode.BY_CLIENT
                ? 'Assigned Clients per Team Member'
                : 'Location Groups per Team Member'}
            </Typography>

            {renderMembers(activeMembers)}
            {!!deletedMembers.length && clientAllocationDate && (
              <Grid item sm={12}>
                <h5 className={styles.subTitle}>Deleted/Deactivated Team Members</h5>
              </Grid>
            )}
            {renderMembers(deletedMembers)}
          </Box>
        </Paper>
        <div className={styles.buttons}>
          <div className={styles.buttonWrapper}>
            <Button color="primary" variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!!Object.keys(errors).length || !isValidAllocationDates || !from || !to}
          >
            Add Client Allocation
          </Button>
        </div>
        <NavigationConfirmModal when={isDirty && !isAdding} description={leavePageDescription} />
      </form>
    </FormProvider>
  );
};

export default Form;
