import { IDictionary } from '../dictionaries/types';
import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { IClientMedicationDelivery } from './types';

interface IRequestInfo {
  clinicId: string;
  teamId: string;
}

interface IMedicationDeliveryItem {
  nonDeliveryReasonId?: number;
  teamMemberId: number;
  note: string;
  date: string;
  lastInjection?: boolean;
  archiveMedication?: boolean;
  nextDueDate?: string;
  hasAlerts?: boolean;
  notificationOn?: string;
  notificationTime?: string;
  timeZoneOffset?: number;
  teamMembersToNotify?: number[];
  clientId: number;
  medicationIds: number[];
  endDate?: string;
}

interface IGetMedicationDeliveriesRequest {
  clientId: string;
  from: string;
  to: string;
}

export interface IReport {
  data: BlobPart;
  headers: Record<string, string>;
}

export interface IMedicationDelivery {
  id: number;
  date: string;
  nonDeliveryReason: IDictionary;
  notDelivered: boolean;
  groupStartDate: string;
  groupEndDate: string;
  note: string;
  teamMember: {
    id: number;
    name: string;
  };
  editInformation: {
    name: string;
    date: string;
  };
}

export interface IMedicationDeliveryRequest extends IMedicationDeliveryItem {
  time?: Date;
  groupEndDate?: string;
  groupDelivery?: boolean;
  groupId?: string;
}

export interface IMedicationDeliveryMedication {
  id: number;
  dose: string;
  injectable: boolean;
  isArchived: boolean;
  startDate?: string | null;
  endDate?: string | null;
  outsideFacility?: boolean;
  medication: {
    name: string;
    id: number;
  };
}
export interface IMedicationDeliveryMedications {
  deliveries: IMedicationDelivery[];
  medication: IMedicationDeliveryMedication;
  nextDueDate: string;
}

export const getClientMedicationDeliveriesList = (
  { clinicId, teamId }: IRequestInfo,
  { clientId, from, to }: IGetMedicationDeliveriesRequest,
): IResponse<{
  medications: IMedicationDeliveryMedications[];
  monitoredBy: IDictionary[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/medication-logistics/delivery/overview?ClientId=${clientId}&From=${from}&To=${to}`,
  });

export const addMedicationDelivery = (
  { clinicId, teamId }: IRequestInfo,
  requestPayload: IMedicationDeliveryRequest,
): IResponse<IMedicationDeliveryMedications[]> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/medication-logistics/delivery`,
    data: requestPayload,
  });

export interface IUpdateMedicationDeliveryRequest
  extends Omit<IMedicationDeliveryRequest, 'clientId'> {
  medicationDeliveryId: string;
}

export const updateMedicationDelivery = (
  { clinicId, teamId }: IRequestInfo,
  { medicationDeliveryId, ...requestPayload }: IUpdateMedicationDeliveryRequest,
): IResponse<IMedicationDeliveryMedications> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/medication-logistics/delivery/${medicationDeliveryId}`,
    data: requestPayload,
  });

export const getMedicationDelivery = (
  { clinicId, teamId }: IRequestInfo,
  medicationDeliveryId: string,
): IResponse<IClientMedicationDelivery> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/medication-logistics/delivery/${medicationDeliveryId}`,
  });

export const getMedicationDeliveryReport = (
  { clinicId, teamId }: IRequestInfo,
  {
    clientId,
    year,
    month,
    timeZoneOffset,
  }: { clientId: string; year: string; month: string; timeZoneOffset: number },
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url:
      `clinic/${clinicId}/act-team/${teamId}/medication-logistics/delivery/report` +
      `?clientId=${clientId}&year=${year}&month=${month}&timeZoneOffset=${timeZoneOffset}`,
    responseType: 'blob',
    headers: { Accept: 'application/pdf' },
  });

export default {};
