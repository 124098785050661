import onGetClientAllocations from './onGetClientAllocations';
import onGetClientAllocation from './onGetClientAllocation';
import onGetOwnClientAllocation from './onGetOwnClientAllocation';
import onGetAllocationClientsByGroup from './onGetAllocationClientsByGroup';
import onGetGlobalUserClientAllocations from './onGetGlobalUserClientAllocations';
import onGetAllocationClientsByGroupGU from './onGetAllocationClientsByGroupGU';
import onGetOwnClientAllocationGU from './onGetOwnClientAllocationGU';
import onGetClientAllocationGU from './onGetClientAllocationGU';
import onAddClientAllocation from './onAddClientAllocation';
import onAddOwnClientAllocation from './onAddOwnClientAllocation';
import onAddOwnClientAllocationGU from './onAddOwnClientAllocationGU';
import onDeleteOwnClientAllocationGU from './onDeleteOwnClientAllocationGU';

export default {
  onGetClientAllocations,
  onGetClientAllocation,
  onGetOwnClientAllocation,
  onGetAllocationClientsByGroup,
  onGetGlobalUserClientAllocations,
  onGetAllocationClientsByGroupGU,
  onGetOwnClientAllocationGU,
  onGetClientAllocationGU,
  onAddClientAllocation,
  onAddOwnClientAllocation,
  onAddOwnClientAllocationGU,
  onDeleteOwnClientAllocationGU,
};
