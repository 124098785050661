import { IAllocationClient } from '~/services/api/clientAllocation/types';

interface IClientIds {
  clients: { id: number }[];
}

const excludeArchivedClients = <T extends IClientIds>(
  allocations: (T & IClientIds)[],
  archivedClients: IAllocationClient[],
): T[] => {
  const clientIdsToExclude = archivedClients.map(client => client.id);
  return (
    allocations?.map(item => ({
      ...item,
      clients: item.clients.filter(client => !clientIdsToExclude.includes(client.id)),
    })) || []
  );
};

export default excludeArchivedClients;
