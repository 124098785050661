import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number, boolean, array } from 'yup';
import {
  validateLengthField,
  zipCode,
  phone,
  validateTenLengthField,
  nullablePhone,
} from '~/ui/configs/form/validation';

import {
  fieldMax,
  fieldMaxTen,
  fieldOnlyNumbers,
  rangeInvalid,
  requiredField,
  requiredFieldIfNoAddress,
} from '~/ui/constants/errorMessages';

const notificationValidationSchema = {
  frequency: string().when('hasAlerts', {
    is: true,
    then: string().required(requiredField).nullable().typeError(requiredField),
    otherwise: string().nullable(),
  }),
  hasAlerts: boolean(),
  notificationOn: string().when('hasAlerts', {
    is: true,
    then: string().required(requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
  teamMembersToNotify: array().when('hasAlerts', {
    is: true,
    then: array().of(number()).min(1, requiredField).required(requiredField),
    otherwise: array().of(number()),
  }),
  time: string().when('hasAlerts', {
    is: true,
    then: string().required(requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
};

const dateValidation = string().when('hasAlerts', {
  is: true,
  then: string().required(requiredField).typeError(requiredField),
  otherwise: string().nullable(),
});

export const triggerValidationSchema = yupResolver(
  object().shape({
    ...notificationValidationSchema,
    note: string().nullable(),
    triggerDate: dateValidation,
    triggerId: number().required(requiredField).typeError(requiredField),
    type: string().required(requiredField).typeError(requiredField),
  }),
);

export const treatmentPlanValidationSchema = yupResolver(
  object().shape({
    setActive: boolean(),
    caseManagerId: number().required(requiredField),
    effectiveDate: string().required(requiredField).typeError(requiredField),
    nextReviewDate: string().required(requiredField).typeError(requiredField),
    goals: array().of(
      object().shape({
        text: string().required(requiredField).typeError(requiredField),
        objectives: array().of(
          object().shape({
            text: string().required(requiredField).typeError(requiredField),
          }),
        ),
      }),
    ),
  }),
);

export const draftTreatmentPlanValidationSchema = object().shape({
  caseManagerId: number().required(requiredField),
});

export const medicationValidationSchema = yupResolver(
  object().shape({
    id: number().required(requiredField).typeError(requiredField),
    note: string().nullable(),
    dose: string().required(requiredField),
    injectable: boolean(),
    startDate: string().required(requiredField).typeError(requiredField),
    endDate: string().required(requiredField).typeError(requiredField),
  }),
);

export const medicationDeliveryValidationSchema = yupResolver(
  object().shape({
    notDelivered: boolean(),
    teamMemberId: number().min(1, requiredField).required(requiredField),
    medicationIds: array().of(number().required(requiredField).typeError(requiredField)),
    note: string().nullable(),
    time: string().required(requiredField).typeError(requiredField),
    date: string().required(requiredField).typeError(requiredField),
    nonDeliveryReasonId: number().when('notDelivered', {
      is: true,
      then: number().required(requiredField).nullable().typeError(requiredField),
      otherwise: number().nullable(),
    }),
    dateRange: array()
      .of(string().required(requiredField).typeError(requiredField))
      .nullable()
      .when('groupDelivery', {
        is: true,
        then: schema =>
          schema
            .required(requiredField)
            .test(
              'is-array-of-two',
              rangeInvalid,
              value => Array.isArray(value) && value.every(date => date),
            ),
        otherwise: schema => schema.nullable(),
      })
      .length(2, rangeInvalid),
  }),
);

export const injectableMedicationDeliveryValidationSchema = yupResolver(
  object().shape({
    notDelivered: boolean(),
    teamMemberId: number().min(1, requiredField).required(requiredField),
    medicationIds: array().of(number().required(requiredField).typeError(requiredField)),
    note: string().nullable(),
    time: string().required(requiredField).typeError(requiredField),
    date: string().required(requiredField).typeError(requiredField),
    nonDeliveryReasonId: number().when('notDelivered', {
      is: true,
      then: number().required(requiredField).nullable().typeError(requiredField),
      otherwise: number().nullable(),
    }),
    lastInjection: boolean().nullable(),
    archiveMedication: boolean().nullable(),
    hasAlerts: boolean().nullable(),
    nextDueDate: string().when('lastInjection', {
      is: true,
      then: string().typeError(requiredField).nullable(),
      otherwise: string().required(requiredField).typeError(requiredField),
    }),
    notificationOn: string().when('hasAlerts', {
      is: true,
      then: string().required(requiredField).typeError(requiredField),
      otherwise: string().nullable(),
    }),
    notificationTime: string().when('hasAlerts', {
      is: true,
      then: string().required(requiredField).typeError(requiredField),
      otherwise: string().nullable(),
    }),
    teamMembersToNotify: array().when('hasAlerts', {
      is: true,
      then: array().of(number()).min(1, requiredField).required(requiredField),
      otherwise: array().of(number()),
    }),
  }),
);

export const deEscalationValidationSchema = yupResolver(
  object().shape({
    id: number().required(requiredField).typeError(requiredField),
    note: string().nullable(),
  }),
);

export const allergyValidationSchema = yupResolver(
  object().shape({
    id: number().required(requiredField).typeError(requiredField),
    note: string().nullable(),
  }),
);

const commonAddressValidation = {
  line1: string().when('setAsPrimary', {
    is: true,
    then: string().required(requiredField).test('length', fieldMax, validateLengthField),
    otherwise: string().nullable(),
  }),
  line2: string().test('length', fieldMax, validateLengthField).nullable(),
  city: string().required(requiredField).test('length', fieldMax, validateLengthField),
  stateId: number().required(requiredField).typeError(requiredField),
  zipCode,
};

export const shelterAddressValidationSchema = yupResolver(
  object().shape({
    name: string().required(requiredField),
    phone: nullablePhone,
    address: object().shape({
      ...commonAddressValidation,
      line1: string().required(requiredField).test('length', fieldMax, validateLengthField),
    }),
  }),
);

export const locationGroupValidationSchema = yupResolver(
  object().shape({
    name: string().required(requiredField),
  }),
);

const locationAdditionalFields = {
  typeId: number().required(requiredField).typeError(requiredField),
  setAsPrimary: boolean(),
  archivePrimary: boolean(),
};

export const locationValidationSchema = yupResolver(
  object().shape({
    ...locationAdditionalFields,
    shelterId: number().when('isShelter', {
      is: true,
      then: number().required(requiredField).typeError(requiredField),
      otherwise: number().nullable(),
    }),
    address: object().when('isShelter', {
      is: true,
      then: object().nullable(),
      otherwise: object().shape({
        ...commonAddressValidation,
        poBox: string()
          .test('length', fieldMaxTen, validateTenLengthField)
          .when(['line1', 'setAsPrimary'], {
            is: (line1: string, setAsPrimary: boolean) => !line1.length && !setAsPrimary,
            then: string()
              .required(requiredFieldIfNoAddress)
              .matches(/^[0-9]+$|^$/, {
                message: fieldOnlyNumbers,
                excludeEmptyString: true,
              }),
            otherwise: string()
              .matches(/^[0-9]+$|^$/, {
                message: fieldOnlyNumbers,
                excludeEmptyString: true,
              })
              .nullable(),
          }),
      }),
    }),
  }),
);

export const phoneValidationSchema = yupResolver(
  object().shape({
    number: phone,
    typeId: number().required(requiredField).typeError(requiredField),
  }),
);

export const secondaryDiagnosisValidationSchema = deEscalationValidationSchema;
